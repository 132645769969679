import React, { ComponentType, useEffect, useState } from 'react'
import { TextField, Grid, Typography, Tooltip, InputProps } from '@mui/material'
import classNames from 'classnames'
import makeStyles from '@mui/styles/makeStyles'
import Autocomplete from '@mui/material/Autocomplete'
import { MdiReactIconProps } from 'mdi-react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import LinkIcon from 'mdi-react/LinkIcon'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import i18n from '../../../translations/i18n'
import colors from '../../../styles/common/colors'
import useDebounce from '../../../hooks/debounce'
import { DEBOUNCE_DELAY } from '../../../config/constants'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  parentIcon: {
    color: colors.parentLunchListColor,
    marginRight: theme.spacing(2)
  },
  outlinedTextField: {
    // fix problem when outlined textfield label does not fit to the container div overflows
    marginTop: 5
  }
}))

/**
 * Get field value if only id is provided without label
 * @param value
 * @param options
 */
const getFieldValue = (value: AutocompleteOption, options?: AutocompleteOption[]) => {
  if (!value.label && options) {
    return options.find(({ value: optionValue }) => value.value === optionValue)
  }
  return value
}

interface ReduxSelectWithAutocompleteProps {
  input: WrappedFieldInputProps
  label: string
  meta: WrappedFieldMetaProps
  options: AutocompleteOption[]
  disabled?: boolean
  /**
   * Custom icon rendered next to option when menu opens
   */
  OptionIcon?: ComponentType<MdiReactIconProps>
  popupIcon?: JSX.Element
  placeholder?: string
  disableClearable?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  helperText?: string
  inputProps?: Partial<InputProps>
  size?: 'small' | 'medium'
  /**
   * If you need to run custom function when input value 'string' changes (not the autocomplete options).
   * Use this for example to get more options from API. */
  onTextFieldInputChangeFunction?: (inputValue: string) => void
}

const ReduxSelectWithAutocomplete = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}: ReduxSelectWithAutocompleteProps) => {
  const [inputFieldValue, setInputValue] = useState(input.value)
  const inputValue = useDebounce(inputFieldValue, DEBOUNCE_DELAY)
  const classes = useStyles()

  const {
    OptionIcon,
    placeholder,
    disableClearable,
    variant,
    helperText,
    inputProps,
    onTextFieldInputChangeFunction,
    ...rest
  } = custom

  useEffect(() => {
    if (typeof onTextFieldInputChangeFunction === 'function') {
      onTextFieldInputChangeFunction(inputValue)
    }
    // Note: only run function when inputValue changes.
    // Do not add onTextFieldInputChangeFunction to dependency array as it would cause infinite loop of running function.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const helperTextValue = (touched && error) || helperText
  return (
    <Autocomplete
      className={classNames({
        // fix problem when outlined textfield label does not fit to the container div overflows
        [classes.outlinedTextField]: variant === undefined || variant === 'outlined' ? true : false
      })}
      onInputChange={
        typeof onTextFieldInputChangeFunction === 'function'
          ? (event: any, value: string) => {
              setInputValue(value)
            }
          : undefined
      }
      disableClearable={disableClearable}
      value={getFieldValue(input.value, custom.options) || null}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      onChange={(event, value) => input.onChange(value)}
      autoComplete
      includeInputInList
      noOptionsText={<>{i18n.t('general.noOptions')}</>}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={params => {
        if (inputProps) {
          params.InputProps = {
            ...params.InputProps,
            ...inputProps
          }
        }
        return (
          <TextField
            {...params}
            variant={variant}
            onFocus={input.onFocus}
            onBlur={() => input.onBlur(undefined)} // make sure onBlur doesn't change field value
            error={touched && invalid}
            helperText={helperTextValue}
            placeholder={placeholder}
            label={label}
            fullWidth
          />
        )
      }}
      renderOption={(props, option) => (
        // key must be unique
        <li {...props} key={`${props.id}`}>
          {option.isParent ? (
            <Grid container alignItems='center'>
              <Tooltip disableInteractive title={i18n.t('general.isParentItem')}>
                <Grid item>
                  <LinkIcon className={classes.parentIcon} />
                </Grid>
              </Tooltip>
              <Grid item xs>
                <Typography variant='body2'>{option.label}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container alignItems='center'>
              {OptionIcon && (
                <Grid item>
                  <OptionIcon className={classes.icon} />
                </Grid>
              )}
              <Grid item xs>
                <Typography variant='body2'>{option.label}</Typography>
              </Grid>
            </Grid>
          )}
        </li>
      )}
      {...rest}
    />
  )
}

export default ReduxSelectWithAutocomplete
