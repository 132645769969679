import { MenuItem, ListItemText, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import React, { Fragment } from 'react'
import PlusIcon from 'mdi-react/PlusIcon'
import CogOutlineIcon from 'mdi-react/CogOutlineIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import { useTranslation } from 'react-i18next'
import { SchemaComponents, TemplateType, ComponentType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import Menu from '../../../components/Menu'
import {
  AddTemplateChild,
  RemoveTemplateChild,
  DuplicateTemplateChild,
  CloseDialog,
  EditSchemaComponent
} from '../../../types/actions'
import Dialog from '../../Dialog'
import EditSchemaComponentForm from './EditSchemaComponentForm'

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex'
  }
}))

/** Array of ComponentTypes, which children cannot be removed */
const disableRemoveFromChildren = [
  ComponentType.listPrice,
  ComponentType.lunchGroups,
  ComponentType.lunchGroup,
  ComponentType.lunchGroupPrice,
  ComponentType.lunchItemContainer,
  ComponentType.lunchItemSpecialDiet,
  ComponentType.lunchItemsContainer,
  // misc list related:
  ComponentType.fishItems,
  ComponentType.meatItems
]

const getEditSchemaComponentFormInitialValues = (
  {
    name,
    allow,
    conditions,
    formatOptions,
    customValue,
    id,
    specialDietType,
    groupIndex,
    editorBackgroundColor
  }: SchemaComponents,
  childIndexes: number[]
) => ({
  name,
  id,
  customValue,
  allow,
  childIndexes,
  conditions,
  formatOptions,
  specialDietType,
  groupIndex,
  editorBackgroundColor
})

interface ActionButtonsProps {
  removeTemplateChild: RemoveTemplateChild
  duplicateTemplateChild: DuplicateTemplateChild
  editSchemaComponent: EditSchemaComponent
  closeDialog: CloseDialog
  childIndexes: number[]
  fieldArrayName: string
  currentIndex: number
  level: number
  currentComponentSchema: SchemaComponents
  templateType: TemplateType
  parentComponentType?: ComponentType
  t: TFunction
}

const ActionButtons = ({
  t,
  removeTemplateChild,
  duplicateTemplateChild,
  editSchemaComponent,
  closeDialog,
  childIndexes,
  fieldArrayName,
  currentIndex,
  level,
  currentComponentSchema,
  parentComponentType,
  templateType
}: ActionButtonsProps) => {
  const { type } = currentComponentSchema
  const editSchemaComponentdialogId = `EditSchemaComponentDialog-${fieldArrayName}-${currentIndex}`

  switch (templateType) {
    case TemplateType.lunch:
      const renderDeleteButton =
        level > 0 && parentComponentType && !disableRemoveFromChildren.includes(parentComponentType)
      const renderSettingsButton =
        type === ComponentType.lunchItemSpecialDiet || type === ComponentType.lunchGroupPrice
      if (renderDeleteButton || renderSettingsButton) {
        return (
          <Fragment>
            {renderDeleteButton && (
              <IconButton
                onClick={() => removeTemplateChild({ childIndexes, fieldArrayName, currentIndex })}
                size='large'>
                <DeleteIcon />
              </IconButton>
            )}
            {renderSettingsButton && (
              <Dialog
                dialogId={editSchemaComponentdialogId}
                title={t('templates.editSchemaComponentForm.title')}
                tooltipTitle={t('templates.editSchemaComponentForm.title')}
                ButtonIcon={CogOutlineIcon}
                Content={
                  <EditSchemaComponentForm
                    dialogId={editSchemaComponentdialogId}
                    onSubmit={editSchemaComponent}
                    closeDialog={closeDialog}
                    submitButtonLabel={t('general.create')}
                    type={type}
                    templateType={templateType}
                    componentType={currentComponentSchema.type}
                    parentComponentType={parentComponentType}
                    t={t}
                    initialValues={{
                      ...getEditSchemaComponentFormInitialValues(
                        currentComponentSchema,
                        childIndexes
                      ),
                      fieldArrayName,
                      currentIndex
                    }}
                  />
                }
              />
            )}
          </Fragment>
        )
      } else {
        return <Fragment />
      }
    case TemplateType.fish:
    case TemplateType.meat:
      if (
        level > 0 &&
        parentComponentType &&
        !disableRemoveFromChildren.includes(parentComponentType)
      ) {
        return (
          <Fragment>
            <IconButton
              onClick={() => removeTemplateChild({ childIndexes, fieldArrayName, currentIndex })}
              size='large'>
              <DeleteIcon />
            </IconButton>
          </Fragment>
        )
      } else {
        return <Fragment />
      }
    case TemplateType.retail:
    case TemplateType.info:
      return level > 0 ? (
        <Fragment>
          <IconButton
            onClick={() => removeTemplateChild({ childIndexes, fieldArrayName, currentIndex })}
            size='large'>
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => duplicateTemplateChild({ childIndexes, fieldArrayName, currentIndex })}
            size='large'>
            <ContentCopyIcon />
          </IconButton>
          <Dialog
            dialogId={editSchemaComponentdialogId}
            title={t('templates.editSchemaComponentForm.title')}
            tooltipTitle={t('templates.editSchemaComponentForm.title')}
            ButtonIcon={CogOutlineIcon}
            Content={
              <EditSchemaComponentForm
                dialogId={editSchemaComponentdialogId}
                onSubmit={editSchemaComponent}
                closeDialog={closeDialog}
                submitButtonLabel={t('general.create')}
                type={type}
                templateType={templateType}
                componentType={currentComponentSchema.type}
                parentComponentType={parentComponentType}
                t={t}
                initialValues={getEditSchemaComponentFormInitialValues(
                  currentComponentSchema,
                  childIndexes
                )}
              />
            }
          />
        </Fragment>
      ) : (
        <Fragment />
      )
    default:
      return <Fragment />
  }
}

const willRenderMenu = (
  templateType: TemplateType,
  currentComponentType: ComponentType,
  parentComponentType?: ComponentType
) => {
  if (templateType === TemplateType.lunch) {
    return currentComponentType === ComponentType.div
  } else if (currentComponentType === ComponentType.products) {
    return true
  } else {
    return (
      parentComponentType !== ComponentType.productData &&
      parentComponentType !== ComponentType.fishItems &&
      parentComponentType !== ComponentType.meatItems &&
      (currentComponentType === ComponentType.div ||
        currentComponentType === ComponentType.productData)
    )
  }
}

interface ExtensionPanelActionsProps {
  addTemplateChild: AddTemplateChild
  removeTemplateChild: RemoveTemplateChild
  duplicateTemplateChild: DuplicateTemplateChild
  editSchemaComponent: EditSchemaComponent
  closeDialog: CloseDialog
  childIndexes: number[]
  fieldArrayName: string
  currentIndex: number
  level: number
  currentComponentSchema: SchemaComponents
  templateType: TemplateType
  parentComponentType?: ComponentType
}

const ExtensionPanelActions: React.FC<ExtensionPanelActionsProps> = ({
  addTemplateChild,
  removeTemplateChild,
  duplicateTemplateChild,
  editSchemaComponent,
  closeDialog,
  childIndexes,
  fieldArrayName,
  currentIndex,
  level,
  currentComponentSchema,
  templateType,
  parentComponentType
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const isMenuVisible = willRenderMenu(
    templateType,
    currentComponentSchema.type,
    parentComponentType
  )
  const buttonProps = {
    t,
    removeTemplateChild,
    duplicateTemplateChild,
    editSchemaComponent,
    closeDialog,
    childIndexes,
    fieldArrayName,
    currentIndex,
    level,
    currentComponentSchema,
    templateType,
    parentComponentType
  }
  return (
    <div className={classes.actions}>
      <ActionButtons {...buttonProps} />
      {isMenuVisible && (
        <Menu
          CustomIcon={PlusIcon}
          tooltipTitle={t('templates.editor.addChild')}
          items={[
            <MenuItem
              key='addDiv'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.div })
              }>
              <ListItemText primary={t('templates.editor.addDiv')} />
            </MenuItem>,
            <MenuItem
              key='addText'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.text })
              }>
              <ListItemText primary={t('templates.editor.addText')} />
            </MenuItem>,
            <MenuItem
              key='addImage'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.image })
              }>
              <ListItemText primary={t('templates.editor.addImage')} />
            </MenuItem>,
            <MenuItem
              key='addVideo'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.video })
              }>
              <ListItemText primary={t('templates.editor.addVideo')} />
            </MenuItem>,
            <MenuItem
              key='addDate'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.date })
              }>
              <ListItemText primary={t('templates.editor.addDate')} />
            </MenuItem>,
            <MenuItem
              key='addQrCode'
              onClick={() =>
                addTemplateChild({ childIndexes, fieldArrayName, type: ComponentType.qrCode })
              }>
              <ListItemText primary={t('templates.editor.addQrCode')} />
            </MenuItem>,
            currentComponentSchema.type === ComponentType.div &&
              templateType === TemplateType.fish && (
                <MenuItem
                  key='addFishTable'
                  onClick={() =>
                    addTemplateChild({
                      childIndexes,
                      fieldArrayName,
                      type: ComponentType.fishItems
                    })
                  }>
                  <ListItemText primary={t('templates.editor.addFishTable')} />
                </MenuItem>
              ),
            currentComponentSchema.type === ComponentType.div &&
              templateType === TemplateType.meat && (
                <MenuItem
                  key='addMeatTable'
                  onClick={() =>
                    addTemplateChild({
                      childIndexes,
                      fieldArrayName,
                      type: ComponentType.meatItems
                    })
                  }>
                  <ListItemText primary={t('templates.editor.addMeatTable')} />
                </MenuItem>
              ),
            (currentComponentSchema.type === ComponentType.div ||
              currentComponentSchema.type === ComponentType.products) &&
              templateType === TemplateType.retail && (
                <MenuItem
                  key='addProductData'
                  onClick={() =>
                    addTemplateChild({
                      childIndexes,
                      fieldArrayName,
                      type: ComponentType.productData
                    })
                  }>
                  <ListItemText primary={t('templates.editor.addProductData')} />
                </MenuItem>
              ),
            currentComponentSchema.type === ComponentType.div &&
              templateType === TemplateType.lunch && (
                <MenuItem
                  key='addLunchItems'
                  onClick={() =>
                    addTemplateChild({
                      childIndexes,
                      fieldArrayName,
                      type: ComponentType.lunchItemsContainer
                    })
                  }>
                  <ListItemText primary={t('templates.editor.addLunchItems')} />
                </MenuItem>
              ),
            currentComponentSchema.type === ComponentType.div &&
              templateType === TemplateType.lunch && (
                <MenuItem
                  key='addMenuSectionPrice'
                  onClick={() =>
                    addTemplateChild({
                      childIndexes,
                      fieldArrayName,
                      type: ComponentType.listPrice
                    })
                  }>
                  <ListItemText primary={t('templates.editor.addListPrice')} />
                </MenuItem>
              )
          ]}
        />
      )}
    </div>
  )
}

export default ExtensionPanelActions
