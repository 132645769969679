import i18n from '../translations/i18n'

interface MediaDeletionProps {
  playlists?: string[]
  screens?: string[]
  products?: string[]
  templates?: string[]
  infopages?: string[]
  contents?: string[]
}

const mediaDeletionFail = ({
  playlists,
  screens,
  products,
  templates,
  infopages,
  contents
}: MediaDeletionProps) => {
  let message = i18n.t('error.media.fileBeingUsedBy')
  const both = playlists && screens
  if (playlists) {
    const playlist = i18n.t('error.media.playlist', { count: playlists.length })
    message = message.concat(` ${playlist} (${playlists.join(', ')})`)
  }
  if (screens) {
    const screen = i18n.t('error.media.screen', { count: screens.length })
    message = both
      ? message.concat(` ${i18n.t('general.and')} ${screen} (${screens})`)
      : message.concat(` ${screen} (${screens.join(', ')})`)
  }
  if (products) {
    const product = i18n.t('error.media.product', { count: products.length })
    message = `${message} ${product} (${products.join(', ')})`
  }
  if (templates) {
    const template = i18n.t('error.media.template', { count: templates.length })
    message = `${message} ${template} (${templates.join(', ')})`
  }
  if (infopages && infopages.length > 0) {
    const infopage = i18n.t('error.media.infopage', { count: infopages.length })
    message = message.concat(` ${infopage} (${infopages.join(', ')})`)
  }
  if (contents && contents.length > 0) {
    const content = i18n.t('error.media.infopage', { count: contents.length })
    message = message.concat(` ${content} (${contents.join(', ')})`)
  }
  return `${message}. ${i18n.t('error.media.couldNotDelete')}.`
}

interface ListDeletionProps {
  playlists?: string[]
  screens?: string[]
}

const getListDeletionErrorMessage = ({ playlists, screens }: ListDeletionProps) => {
  const messageParts: string[] = []

  if (playlists && playlists.length > 0) {
    const playlistMessage = `${i18n.t('error.media.playlist', {
      count: playlists.length
    })} (${playlists.join(', ')})`
    messageParts.push(playlistMessage)
  }

  if (screens && screens.length > 0) {
    const listMessage = `${i18n.t('error.media.screen', { count: screens.length })} (${screens.join(
      ', '
    )})`
    messageParts.push(listMessage)
  }

  if (messageParts.length === 0) {
    return i18n.t('error.list.couldNotDelete')
  }

  const message = messageParts.join(` ${i18n.t('general.and')} `)
  return `${i18n.t('error.list.listBeingUsedBy')} ${message}. ${i18n.t(
    'error.list.couldNotDelete'
  )}.`
}

interface ProductDeletionProps {
  lists?: string[]
  playlists?: string[]
}

const getProductDeletionErrorMessage = ({ lists, playlists }: ProductDeletionProps) => {
  const messageParts: string[] = []

  if (playlists && playlists.length > 0) {
    const playlistMessage = `${i18n.t('error.media.playlist', {
      count: playlists.length
    })} (${playlists.join(', ')})`
    messageParts.push(playlistMessage)
  }

  if (lists && lists.length > 0) {
    const listMessage = `${i18n.t('error.product.list', { count: lists.length })} (${lists.join(
      ', '
    )})`
    messageParts.push(listMessage)
  }

  if (messageParts.length === 0) {
    return i18n.t('error.product.couldNotDelete')
  }

  const message = messageParts.join(` ${i18n.t('general.and')} `)
  return `${i18n.t('error.product.productUsedBy')} ${message}. ${i18n.t(
    'error.product.couldNotDelete'
  )}.`
}
const channelDeletionFail = (screens: string[]) => {
  const message = i18n.t('error.channel.usedBy')
  return `${message}\
  ${i18n.t('error.channel.screens', { count: screens.length, screens: screens.join(', ') })}\
  ${i18n.t('error.channel.couldNotDelete')}.`
}

interface TemplateDeletionFail {
  lists?: string[]
  playlists?: string[]
  infopages?: string[]
}

const templateDeletionFail = ({ lists, playlists, infopages }: TemplateDeletionFail) => {
  let message = i18n.t('error.template.usedBy')
  if (lists && lists.length > 0) {
    const list = i18n.t('error.product.list', { count: lists.length })
    message = message.concat(` ${list} (${lists.join(', ')})`)
  }
  if (playlists && playlists.length > 0) {
    const playlist = i18n.t('error.media.playlist', { count: playlists.length })
    message = message.concat(` ${playlist} (${playlists.join(', ')})`)
  }
  if (infopages && infopages.length > 0) {
    const infopage = i18n.t('error.media.infopage', { count: infopages.length })
    message = message.concat(` ${infopage} (${infopages.join(', ')})`)
  }
  return `${message}. ${i18n.t('error.template.couldNotDelete')}.`
}

interface PlaylistDeletionFail {
  screens?: string[]
  contents?: string[]
}

const playlistDeletionFail = ({ screens, contents }: PlaylistDeletionFail) => {
  let message = ''
  if (screens) {
    message = `${message} ${i18n.t('error.media.screen', {
      count: screens.length
    })} (${screens.join(', ')})`
  }
  if (contents) {
    message = `${message} ${i18n.t('error.media.infopage', {
      count: contents.length
    })}  (${contents.join(', ')})`
  }
  return `${i18n.t('error.playlist.couldNotDeleteUsedBy')} ${message}.`
}

interface ContentDeletionFail {
  playlists?: string[]
  contents?: string[]
}

const contentDeletionFail = ({ playlists, contents }: ContentDeletionFail) => {
  let message = ''
  if (playlists) {
    message = `${message} ${i18n.t('error.media.playlist', {
      count: playlists.length
    })} (${playlists.join(', ')})`
  }
  if (contents) {
    message = `${message} ${i18n.t('error.media.infopage', {
      count: contents.length
    })} (${contents.join(', ')})`
  }
  return `${i18n.t('error.content.couldNotDeleteUsedBy')} ${message}.`
}

export {
  playlistDeletionFail,
  channelDeletionFail,
  mediaDeletionFail,
  getListDeletionErrorMessage,
  getProductDeletionErrorMessage,
  templateDeletionFail,
  contentDeletionFail
}
