import { Grid, MenuItem, Paper, Button, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector, change } from 'redux-form'
import { TemplateType, CreateTemplateType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import DialogActions from '../../../components/DialogActions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { CloseDialog, CreateTemplate, UpdateTemplate } from '../../../types/actions'
import { required, customInterval } from '../../../validation'
import {
  ReduxTextField,
  ReduxDurationField,
  ReduxSwitch
} from '../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../types/states'
import { parseNumberField } from '../../../utils/forms'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  removeDurationIcon: {
    margin: 8
  },
  durationContainer: {
    padding: theme.spacing(1)
  }
}))

interface TemplateTypeSelectionFieldProps {
  isUpdate?: boolean
  t: TFunction
}
const TemplateTypeSelectionField = ({ isUpdate, t }: TemplateTypeSelectionFieldProps) => (
  <Field
    fullWidth
    name='type'
    label={t('lists.type')}
    component={ReduxTextField}
    select
    required
    validate={[required]}
    disabled={isUpdate}>
    {Object.values(isUpdate ? TemplateType : CreateTemplateType).map(templateType => (
      <MenuItem key={templateType} value={templateType}>
        {templateType}
      </MenuItem>
    ))}
  </Field>
)

interface OwnProps {
  submitButtonLabel: string
  onSubmit: CreateTemplate | UpdateTemplate
  closeDialog: CloseDialog
  dialogId: string
  /**
   * Is form used to update template.
   * If so, disable changing template type.
   */
  isUpdate?: boolean
  initialValues?: any
}

interface StateProps {
  selectedType?: TemplateType
  selectedIsMasterTemplate?: boolean
}

interface DispatchProps {
  clearDuration: () => void
}

type CreateTemplateFormProps = OwnProps & StateProps & DispatchProps

const CreateTemplateForm: React.FC<CreateTemplateFormProps &
  InjectedFormProps<{}, CreateTemplateFormProps>> = ({
  submitButtonLabel,
  handleSubmit,
  onSubmit,
  submitting,
  closeDialog,
  dialogId,
  isUpdate,
  selectedType,
  selectedIsMasterTemplate,
  clearDuration,
  error,
  initialValues
}) => {
  const [durationVisible, setDurationVisible] = useState(initialValues?.duration ? true : false)
  const [t] = useTranslation()
  const classes = useStyles()

  const removeDuration = () => {
    clearDuration()
    setDurationVisible(false)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container className={classes.formContainer} rowSpacing={2}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TemplateTypeSelectionField t={t} isUpdate={isUpdate} />{' '}
        </Grid>

        {(selectedType === TemplateType.retail || selectedType === TemplateType.lunch) && (
          <Fragment>
            <Grid item xs={12}>
              <Field
                disabled={isUpdate}
                name='isMasterTemplate'
                label={t('templates.supportMultipleProducts')}
                component={ReduxSwitch}
              />
            </Grid>
            {!selectedIsMasterTemplate && (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name='maxItems'
                  label={t('templates.maxItems')}
                  component={ReduxTextField}
                  type='number'
                />
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
      {durationVisible ? (
        <Paper className={classes.durationContainer}>
          <Grid container>
            <Grid item xs={10}>
              <Field
                fullWidth
                name='duration'
                label={`${t('media.duration')} (${t('general.optional')})`}
                component={ReduxDurationField}
                parse={parseNumberField}
                type='number'
                required
                validate={customInterval}
                defaultValue={7}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.removeDurationIcon}
                onClick={() => removeDuration()}
                size='large'>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Button startIcon={<PlusIcon />} onClick={() => setDurationVisible(true)}>
          {t('templates.forms.addDuration')}
        </Button>
      )}

      <ErrorMessage message={error} />
      <DialogActions
        submitButtonLabel={submitButtonLabel}
        submitting={submitting}
        closeDialog={closeDialog}
        dialogId={dialogId}
      />
    </form>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  selectedType: formValueSelector('CreateTemplateForm')(state, 'type'),
  selectedIsMasterTemplate: formValueSelector('CreateTemplateForm')(state, 'isMasterTemplate')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clearDuration: () => dispatch(change('CreateTemplateForm', 'duration', undefined))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, CreateTemplateFormProps>({
    form: 'CreateTemplateForm'
  })(CreateTemplateForm)
)
