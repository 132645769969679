import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { SubmissionError, change, destroy } from 'redux-form'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import {
  ProductImageProvider,
  ListType,
  Customer,
  PrefillProductData,
  PackageUnit,
  GeneralRetailProduct,
  Product,
  RetailProduct,
  GeneralProductUI,
  Media,
  ProductPlaylistItemUI,
  PlaylistItemType,
  PharmacyMasterProduct,
  PlaylistUI,
  PharmacySearchCriteria,
  CreatePharmacyMasterProductProps,
  PricerProduct
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import {
  getProductsFail,
  getProducts,
  getProductsSuccess,
  prefillRetailProductFormFail,
  prefillRetailProductForm,
  checkProductExists,
  listProductsSuccess,
  listProductsFail,
  listProducts,
  deleteProductFail,
  deleteProductSuccess,
  deleteProduct,
  listProductsFromForm,
  prefillRetailProductFormSuccess,
  createOrUpdateGeneralProduct,
  createOrUpdateGeneralProductFail,
  createOrUpdateGeneralProductSuccess,
  createOrUpdateRetailProduct,
  createOrUpdateRetailProductFail,
  createOrUpdateRetailProductSuccess,
  updatePlaylistProductSuccess,
  updateProductDetails,
  updateProductDetailsFail,
  updateProductDetailsSuccess,
  updatePharmacyProductFail,
  updatePharmacyProduct,
  getMasterPharmacyProduct,
  getMasterPharmacyProductFail,
  getMasterPharmacyProductSuccess,
  createMasterPharmacyProductSuccess,
  createMasterPharmacyProductFail,
  createMasterPharmacyProduct,
  checkMasterProductExists
} from '../actions/products'
import { selectEnvironmentIdFromPathname, selectContentIdFromPathname } from '../selectors/routing'
import Api from '../services/api/products'
import { selectSelectedCustomerId, selectCustomerById } from '../selectors/customers'
import i18n from '../translations/i18n'
import {
  selectListById,
  selectListCustomerById,
  selectListSize,
  selectListTypeByListId
} from '../selectors/lists'
import { closeDialog } from '../actions/dialogs'
import { convertToEan } from '../utils/ean'
import { selectImagesAsArray, selectMediaByKey, selectSelectedFolderId } from '../selectors/media'
import { getProductDeletionErrorMessage } from '../utils/message'
import { selectAddProductAsPlaylistItemCustomerId } from '../selectors/forms'
import {
  CreateGeneralProduct,
  CreateRetailProduct,
  isUpdateGeneralProduct,
  SearchProductByTermParams,
  CreatePharmacyMasterProductFormData
} from '../types/products'
import {
  handleCreateOrUpdateGeneralProductParams,
  handleCreateOrUpdateRetailProductParams,
  handleListProductsFromFormParams,
  handleUpdatePharmacyProductParams,
  FormPayloadParameters
} from '../types/formData'
import { selectCustomer } from '../actions/customers'
import MediaApi from '../services/api/media'
import { isPrefillSupported } from '../utils/customers'

import { KeyWithUrl } from '../types/media'
import { parseRetailProductTagsFromFormData } from '../utils/products'
import { addPlaylistItemSuccess } from '../actions/playlists'
import { selectUserSub } from '../selectors/users'
import { addListItemSuccess, updateProductFromList } from '../actions/lists'
import { selectTemplateById } from '../selectors/templates'
import { selectSelectedPlaylist } from '../selectors/playlists'
import { selectCurrentItemFieldName } from '../selectors/campaigns'
import { clearCurrentCampaignItemFieldName } from '../actions/campaigns'
import { isNumber } from '../validation'
import { uploadFile } from './media'

function* addKeyToProduct(product: GeneralRetailProduct, loadUrls?: boolean) {
  if (product.key) {
    const media: Media | undefined = yield select(selectMediaByKey(product.key))
    // load url if media does not have urls already
    if (loadUrls && media?.url === undefined) {
      const productWithUrls: GeneralProductUI = yield call(addMediaToProduct, product)
      return productWithUrls
    }
    return {
      ...product,
      productMedia: {
        key: product.key,
        url: media?.url,
        thumbnailUrl: media?.thumbnailUrl
      }
    }
  }
  return product
}

const getSearchTermFromInputString = (input: string) => {
  if (input.length > 1 && /^[a-zA-Z äöå0-9]+$/.test(input)) {
    const searchTerm = isNumber(input) ? convertToEan(input) : input
    if (searchTerm) {
      return searchTerm
    } else {
      throw new Error(i18n.t('error.list.productInputFail'))
    }
  } else {
    throw new Error(i18n.t('error.list.productInputFail'))
  }
}

interface HandleGetProductsParams {
  payload: SearchProductByTermParams
}

/**
 * Get products search suggestions
 */
export function* handleGetProducts({ payload: { input, loadUrls } }: HandleGetProductsParams) {
  try {
    const searchTerm = getSearchTermFromInputString(input)
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const listId = yield select(selectContentIdFromPathname)

    let customerId: string | undefined = yield select(selectSelectedCustomerId)
    const listType: ListType | undefined = yield select(selectListTypeByListId(listId))

    // if customerId is not in state (editList page)
    if (!customerId) {
      customerId = yield select(selectListCustomerById(listId))
    }
    // addProductAsItem form auto selected customerId
    if (!customerId) {
      customerId = yield select(selectAddProductAsPlaylistItemCustomerId)
    }

    const customer: Customer | undefined = yield select(selectCustomerById(customerId))
    // If customer has productForm or fish/meat-list
    if (customer?.productForm) {
      const results: Product[] = yield call(
        Api.getSuggestions,
        environmentId,
        customer.customerId,
        listType || ListType.retail,
        searchTerm
      )
      const products: Product[] = yield all(
        results.map(product => {
          if ((product as GeneralRetailProduct).key) {
            return call(addKeyToProduct, product as GeneralRetailProduct, loadUrls)
          }
          return product
        })
      )
      yield put(getProductsSuccess(products))
    } else if (customer?.integration?.pricer?.username) {
      // when pricer integration exists for current customer
      const product: PricerProduct = yield call(
          Api.getPricerProductById,
          environmentId,
          customer.customerId,
          searchTerm
        )
        // include pre-weighed ean code for search field options // TODO: refactor this in the future
      ;(product as any).preWeighedId = searchTerm !== input ? searchTerm : undefined
      yield put(getProductsSuccess([product]))
    } else {
      throw new Error(i18n.t('error.list.productInputFail'))
    }
  } catch (error) {
    yield put(getProductsFail(error.message))
  }
}

export function* uploadProductImage(environmentId: string, file: File) {
  const existingKeys = yield select(selectImagesAsArray)
  const parentFolderId: string | undefined = yield select(selectSelectedFolderId)
  const media: Media = yield call(uploadFile, {
    file,
    existingKeys,
    environmentId,
    parentFolderId
  })
  return media
}

function* addNewRetailProductToList(listId: string, product: RetailProduct) {
  const listSize = yield select(selectListSize(listId))
  const { templateId } = yield select(selectListById(listId))
  const { maxItems } = yield select(selectTemplateById(templateId))
  if (maxItems && listSize >= maxItems) {
    toast.info(i18n.t('error.list.itemAddedMaxReached', { maxItems }))
  } else {
    yield put(
      addListItemSuccess({
        listId,
        item: {
          product,
          productId: product.productId,
          itemId: nanoid()
        }
      })
    )
  }
}

/**
 * Create or update retail product from products table or edit list view
 */
function* handleCreateOrUpdateRetailProduct({
  payload: { formData, resolve, reject }
}: handleCreateOrUpdateRetailProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const customerId: string = yield select(selectSelectedCustomerId)
    const {
      productId,
      name,
      description,
      price,
      unit,
      packageSize,
      packageUnit,
      productTags,
      imageUrl,
      country,
      qualityClass,
      scaleNumber,
      approxPriceUnit,
      approxPrice,
      // listId exists if edit list view
      listId,
      // given if updating product
      isUpdate
    } = formData
    const productIdEAN = convertToEan(productId)
    if (productIdEAN === undefined) {
      throw new Error('invalid productId')
    }

    const createRetailProductProps: CreateRetailProduct = {
      productId: productIdEAN,
      imageUrl,
      name,
      country,
      qualityClass,
      description,
      scaleNumber,
      price,
      unit,
      packageSize,
      packageUnit,
      approxPriceUnit,
      approxPrice,
      tags: parseRetailProductTagsFromFormData(productTags)
    }

    const newProduct: RetailProduct = isUpdate
      ? yield call(
          Api.updateProduct,
          environmentId,
          customerId,
          ListType.retail,
          formData.productId,
          createRetailProductProps
        )
      : yield call(
          Api.createProduct,
          environmentId,
          customerId,
          ListType.retail,
          createRetailProductProps
        )
    yield put(createOrUpdateRetailProductSuccess(newProduct))

    if (isUpdate && listId) {
      // if editing existing list product
      yield put(updateProductFromList({ listId, productId, updatedProduct: newProduct }))
    } else if (listId) {
      // if adding retail product to list
      yield call(addNewRetailProductToList, listId, newProduct)
    }

    yield put(closeDialog())
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    } else {
      const status = path(['response', 'status'], error)
      const _error =
        status === 403
          ? i18n.t('error.product.alreadyExists', {
              productId: formData.productId || formData.name
            })
          : i18n.t('error.product.somethingWrongCreate')

      yield call(reject, new SubmissionError({ _error }))
    }

    yield put(createOrUpdateRetailProductFail(error.message))
  }
}

function* addNewGeneralProductToPlaylist(
  playlistId: string,
  product: GeneralProductUI,
  customerId: string,
  templateId: string
) {
  const { productId } = product
  const userSub = yield select(selectUserSub)
  const item: ProductPlaylistItemUI = {
    productId,
    product,
    customerId,
    type: PlaylistItemType.product,
    templateId,
    itemId: nanoid(),
    userSub
  }
  yield put(addPlaylistItemSuccess(item))
}

function* handleUpdatePharmacyProduct({
  payload: { formData, resolve, reject }
}: handleUpdatePharmacyProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { template, product, itemId } = formData
    const { templateId, templateEnvironmentId } = template
    const updatedProduct = yield call(
      Api.updateCustomerDrug,
      environmentId,
      product.customerId,
      product
    )
    const selectedPlaylist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    if (selectedPlaylist) {
      yield put(
        updatePlaylistProductSuccess({
          playlistId: selectedPlaylist.playlistId,
          templateId,
          templateEnvironmentId,
          itemId,
          updatedProduct
        })
      )
      yield put(closeDialog())
      resolve()
    }
    throw new Error('PlaylistId missing')
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.product.somethingWrongUpdate')
      })
    )
    yield put(updatePharmacyProductFail(error.message))
  }
}

/**
 * Create or update general product from products table or edit playlist view
 */
function* handleCreateOrUpdateGeneralProduct({
  payload: { formData, resolve, reject }
}: handleCreateOrUpdateGeneralProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const {
      name,
      description,
      price,
      comparisonUnit,
      productImage,
      discount,
      discountBatchSize,
      deposit,
      unit,
      packageSize,
      packageUnit,
      discountPrice,
      discountValidString,
      key
    } = formData

    const customerId: string = formData.customerId
      ? formData.customerId.value // customerId exist in form when creating product as playlist item
      : yield select(selectSelectedCustomerId)

    const createGeneralProductProps: CreateGeneralProduct = {
      name,
      description,
      price,
      comparisonUnit,
      discount,
      discountPrice,
      discountBatchSize,
      discountValidString,
      deposit,
      unit,
      packageSize,
      packageUnit,
      key
    }
    if (!discount) {
      delete createGeneralProductProps.discountPrice
      delete createGeneralProductProps.discountValidString
      delete createGeneralProductProps.discountBatchSize
    }

    let uploadedMedia: Media | undefined
    // upload new product image if given
    if (productImage && productImage instanceof File) {
      const uploadedMedia: Media = yield call(uploadProductImage, environmentId, productImage)
      createGeneralProductProps.key = uploadedMedia.key
    }

    const newProduct: GeneralRetailProduct = isUpdateGeneralProduct(formData)
      ? yield call(
          Api.updateProduct,
          environmentId,
          customerId,
          ListType.retail,
          formData.productId,
          {
            ...createGeneralProductProps,
            productId: formData.productId
          } as GeneralRetailProduct
        )
      : yield call(
          Api.createProduct,
          environmentId,
          customerId,
          ListType.retail,
          createGeneralProductProps
        )

    const newProductWithMedia: GeneralProductUI =
      uploadedMedia !== undefined
        ? {
            ...newProduct,
            productMedia: {
              key: uploadedMedia.key,
              url: uploadedMedia.url,
              thumbnailUrl: uploadedMedia.thumbnailUrl
            }
          }
        : yield call(addMediaToProduct, newProduct)

    // When creating general product and adding as playlist item
    const selectedPlaylist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    if (isUpdateGeneralProduct(formData) && selectedPlaylist && formData.template) {
      const { templateId } = formData.template
      const { itemId } = formData
      // update existing playlist item's product
      yield put(
        updatePlaylistProductSuccess({
          templateId,
          itemId,
          updatedProduct: newProductWithMedia
        })
      )
    } else if (selectedPlaylist && formData.template) {
      const { templateId } = formData.template
      yield call(
        addNewGeneralProductToPlaylist,
        selectedPlaylist.playlistId,
        newProductWithMedia,
        customerId,
        templateId
      )
      yield put(destroy('AddProductWizardForm'))
    }

    yield put(createOrUpdateGeneralProductSuccess(newProductWithMedia))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.product.somethingWrongUpdate')
        })
      )
    }
    yield put(createOrUpdateGeneralProductFail(error.message))
  }
}

interface HandleCheckProductExistsError {
  productId: string
}

interface HandleCheckProductExistsParams {
  payload: {
    productId: string
    resolve: () => void
    reject: (error: HandleCheckProductExistsError) => void
  }
}

export function* handleCheckProductExists({
  payload: { productId, resolve, reject }
}: HandleCheckProductExistsParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const customerId: string = yield select(selectSelectedCustomerId)
    const ean = convertToEan(productId)
    const product = yield call(Api.getProductById, environmentId, customerId, ListType.retail, ean)
    if (product) {
      yield call(reject, { productId: i18n.t('error.product.alreadyExists', { productId: ean }) })
    }
  } catch (error) {
    resolve()
  }
}

interface HandleCheckMasterProductExistsParams {
  payload: {
    productId: string
    resolve: () => void
    reject: (error: any) => void
  }
}

export function* handleCheckMasterProductExists({
  payload: { productId, resolve, reject }
}: HandleCheckMasterProductExistsParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const product = yield call(
      Api.searchDrugs,
      environmentId,
      productId.length < 10 ? PharmacySearchCriteria.vnr : PharmacySearchCriteria.ean,
      productId
    )
    if (product.length === 1) {
      yield call(reject, {
        _error: i18n.t('error.product.alreadyExists', { productId: productId })
      })
    }
    resolve()
  } catch (error) {
    resolve()
  }
}

function* getImageUrl(provider: ProductImageProvider, ean: string) {
  const { url } = yield call(Api.generateValidImageUrl, provider, ean)
  if (!url) {
    throw new Error('Image not found')
  }
  return url
}

function* prefillRetailProductFormValues(
  form: string,
  environmentId: string,
  customerId: string,
  productId: string
) {
  const {
    name,
    country,
    unit,
    packageUnit,
    packageSize,
    price,
    approxPrice,
    approxPriceUnit
  }: PrefillProductData = yield call(
    Api.getProductPrefillById,
    environmentId,
    customerId,
    productId
  )
  yield put(change(form, 'name', name))
  if (country) {
    yield put(change(form, 'country', country))
  }
  if (unit && PackageUnit[`${unit}` as PackageUnit]) {
    yield put(change(form, 'unit', unit))
  }
  // packageSize !== 1 because almost all hevi products have package size 1 kg even though there is no real packge
  if (
    packageSize !== undefined &&
    packageSize !== 1 &&
    packageUnit &&
    PackageUnit[`${packageUnit}` as PackageUnit]
  ) {
    yield put(change(form, 'packageUnit', packageUnit))
    yield put(change(form, 'packageSize', packageSize))
  }
  if (price) {
    yield put(change(form, 'price', price))
  }
  if (approxPrice) {
    yield put(change(form, 'approxPrice', approxPrice))
    yield put(change(form, 'approxPriceUnit', approxPriceUnit))
  }
  // required to show user when fields are prefilled, in order to make sure that values are valid
  yield put(change(form, 'isPrefilled', true))
}

interface HandlePrefillRetailProductFormParams {
  payload: {
    productId: string
    form: string
    field: string
  }
}
/**
 * Generate product image url from productId if customer has image property.
 * Store valid image url to form field
 * @param param0
 */
export function* handlePrefillRetailProductForm({
  payload: { productId, form, field }
}: HandlePrefillRetailProductFormParams) {
  try {
    if (!(productId.length === 4 || productId.length === 13)) {
      throw new Error('EAN/PLU must be 4 or 13 in length')
    }
    const selectedCustomerId: string | undefined = yield select(selectSelectedCustomerId)
    const customer: Customer | undefined = yield select(selectCustomerById(selectedCustomerId))
    if (customer) {
      const { environmentId, customerId, images } = customer
      const kRuoka = images?.kRuoka
      const foodie = images?.foodie
      if (!kRuoka && !foodie) {
        throw new Error('Customer does not have an image attribute')
      }
      const provider = kRuoka ? ProductImageProvider.kRuoka : ProductImageProvider.foodie
      const ean = convertToEan(productId)
      if (ean) {
        const url = yield call(getImageUrl, provider, ean)
        yield put(change(form, field, url))

        // if Kruoka prefill integration is set, prefill product values
        if (isPrefillSupported(customer)) {
          yield call(prefillRetailProductFormValues, form, environmentId, customerId, ean)
        }

        yield put(prefillRetailProductFormSuccess())
      } else {
        throw new Error('Non valid ean')
      }
    }
  } catch (error) {
    yield put(change(form, field, undefined))
    yield put(prefillRetailProductFormFail(error.message))
  }
}

/** Add media urls to product if key provided */
export const addMediaToProduct = async (product: GeneralRetailProduct) => {
  const { key } = product
  if (key) {
    const { url, thumbnailUrl }: KeyWithUrl = await MediaApi.getMediaUrl(key, true)
    const generalProductUI: GeneralProductUI = {
      ...product,
      productMedia: {
        key,
        url,
        thumbnailUrl
      }
    }
    return generalProductUI
  }
  return product
}

interface HandleListProductsParams {
  payload: {
    environmentId: string
    customerId: string
    type: ListType
  }
}

export function* handleListProducts({
  payload: { environmentId, customerId, type }
}: HandleListProductsParams) {
  try {
    if (type === ListType.retail) {
      const productsArray: (RetailProduct | GeneralRetailProduct)[] = yield call(
        Api.listProducts,
        environmentId,
        customerId,
        type
      )
      yield put(listProductsSuccess({ productsArray, type }))
    } else {
      throw new Error('Customer has no general retail tables')
    }
    yield put(closeDialog('SelectCustomerDialog'))
  } catch (error) {
    yield put(listProductsFail(error.message))
  }
}

export function* handleListProductsFromForm({
  payload: { formData, resolve, reject }
}: handleListProductsFromFormParams) {
  try {
    const {
      type,
      customerId: { value }
    } = formData
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield put(selectCustomer(value))
    yield put(listProducts({ environmentId, customerId: value, type }))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(listProductsFail(error.message))
    reject(error)
  }
}

interface HandleDeleteProductParams {
  payload: {
    productId: string
    type: ListType
  }
}

export function* handleDeleteProduct({ payload: { productId, type } }: HandleDeleteProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const customerId = yield select(selectSelectedCustomerId)
    yield call(Api.deleteProduct, environmentId, customerId, type, productId)
    yield put(closeDialog())
    yield put(deleteProductSuccess(productId))
  } catch (error) {
    const lists: string[] | undefined = path(['response', 'data', 'lists'], error)
    const playlists: string[] | undefined = path(['response', 'data', 'playlists'], error)
    const errorMessage = getProductDeletionErrorMessage({ lists, playlists })
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deleteProductFail())
  }
}

/**
 * Saga handler to update all customer retail products' images.
 * - ToDo: support also price updating when full integrations are supported
 */
function* handleUpdateProductDetails() {
  try {
    const selectedCustomerId = yield select(selectSelectedCustomerId)
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.updateSokProductDetails, environmentId, selectedCustomerId, {
      updateImage: true
    })
    yield put(closeDialog())
    yield put(updateProductDetailsSuccess())
  } catch (error) {
    yield put(updateProductDetailsFail(error.message))
  }
}

interface HandleGetMasterPharmacyProductParams {
  payload: {
    /** name of form to fill with master product data */
    form: string
    ean: string
    property: 'price' | 'description'
  }
}

/**
 * Handler function to revert some customer product properties with master product properties.
 *  - use case example: User has entered custom description, but wants to revert to master product description.
 */
function* handleGetMasterPharmacyProduct({
  payload: { form, ean, property }
}: HandleGetMasterPharmacyProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const masterProducts: PharmacyMasterProduct[] = yield call(
        Api.searchDrugs,
        environmentId,
        PharmacySearchCriteria.ean,
        ean
      )
      const masterProduct = masterProducts[0]
      if (masterProduct) {
        if (property === 'price') {
          if (masterProduct.price) {
            yield put(change(form, 'product.price', masterProduct.price))
          } else {
            toast.error(i18n.t('error.product.masterPriceNotFound'))
          }
        }
        if (property === 'description') {
          if (masterProduct.description) {
            yield put(change(form, 'product.description', masterProduct.description))
          } else {
            toast.error(i18n.t('error.product.masterDescriptionNotFound'))
          }
        }
      }
      yield put(getMasterPharmacyProductSuccess())
    }
  } catch (error) {
    yield put(getMasterPharmacyProductFail(error.message))
  }
}

function* handleCreatePharmacyMasterProduct({
  payload: { formData, resolve, reject }
}: FormPayloadParameters<CreatePharmacyMasterProductFormData>) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { customerId, product } = formData
    const productData: CreatePharmacyMasterProductProps = {
      ...product
    }

    const { ean, vnr, name, description }: PharmacyMasterProduct = yield call(
      Api.createMasterDrug,
      environmentId,
      customerId,
      productData
    )
    const item: string | undefined = yield select(selectCurrentItemFieldName)
    if (item) {
      const form = 'EditCampaignForm'
      yield put(change(form, `${item}.name`, name))
      yield put(change(form, `${item}.description`, description))
      yield put(change(form, `${item}.vnr`, vnr))
      yield put(change(form, `${item}.productId`, ean))
      yield put(clearCurrentCampaignItemFieldName())
    }
    resolve()
    yield put(closeDialog())
    yield put(createMasterPharmacyProductSuccess())
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.product.somethingWrongUpdate')
      })
    )
    yield put(createMasterPharmacyProductFail(error.message))
  }
}

function* watchProductsActions() {
  yield all([
    takeLatest(getProducts, handleGetProducts),
    takeLatest(prefillRetailProductForm, handlePrefillRetailProductForm),
    takeLatest(checkProductExists, handleCheckProductExists),
    takeLatest(checkMasterProductExists, handleCheckMasterProductExists),
    takeLatest(listProducts, handleListProducts),
    takeLatest(listProductsFromForm, handleListProductsFromForm),
    takeLatest(deleteProduct, handleDeleteProduct),
    takeLatest(createOrUpdateGeneralProduct, handleCreateOrUpdateGeneralProduct),
    takeLatest(createOrUpdateRetailProduct, handleCreateOrUpdateRetailProduct),
    takeLatest(updatePharmacyProduct, handleUpdatePharmacyProduct),
    takeLatest(updateProductDetails, handleUpdateProductDetails),
    takeLatest(getMasterPharmacyProduct, handleGetMasterPharmacyProduct),
    takeLatest(createMasterPharmacyProduct, handleCreatePharmacyMasterProduct)
  ])
}

export default [watchProductsActions]
