import { SubmissionError } from 'redux-form'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import { all, takeLatest, put, select, call, take } from 'redux-saga/effects'
import {
  ListType,
  MediaPlaylistItemUI,
  ProductPlaylistItemUI,
  PlaylistUI,
  RevolverPlaylistItemUI,
  PlaylistItemUI,
  PlaylistItemType,
  GeneralRetailProduct,
  Media,
  UrlPlaylistItem,
  ListPlaylistItem,
  ProductPlaylistItem,
  InfopagePlaylistItem,
  RevolverPlaylistItem,
  MediaPlaylistItem,
  MediaType,
  GeneralProductUI,
  ListPlaylistItemUI,
  ListUI,
  InfopagePlaylistItemUI,
  ContentPlaylistItem,
  ContentPlaylistItemUI,
  ContentUI,
  Template,
  isPharmacyCustomerProduct,
  PharmacyProduct,
  ImagePlaylistItem,
  isLegacySchedule,
  convertLegacySchedule,
  Infopage,
  InfopageExtensive,
  PlaylistItemTagConditions,
  PlaylistItemConditions
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { addMediaFail } from '../actions/media'
import {
  reorderItems,
  reorderItemsFail,
  listPlaylists,
  listPlaylistsSuccess,
  listPlaylistsFail,
  createPlaylist,
  createPlaylistSuccess,
  createPlaylistFail,
  addPlaylistItems,
  addPlaylistItemsSuccess,
  addPlaylistItemsFail,
  getPlaylist,
  getPlaylistSuccess,
  getPlaylistFail,
  deletePlaylist,
  deletePlaylistSuccess,
  deletePlaylistFail,
  deletePlaylistItem,
  deletePlaylistItemSuccess,
  deletePlaylistItemFail,
  updatePlaylistSuccess,
  updatePlaylistFail,
  updatePlaylist,
  hidePlaylistItem,
  hidePlaylistItemFail,
  hidePlaylistItemSuccess,
  schedulePlaylistItem,
  schedulePlaylistItemSuccess,
  schedulePlaylistItemFail,
  addPlaylistItemSuccess,
  addPlaylistItemCondition,
  addPlaylistItemConditionSuccess,
  addPlaylistItemConditionFail,
  checkUrlIsValid,
  intervalPlaylistItem,
  intervalPlaylistItemSuccess,
  intervalPlaylistItemFail,
  removePlaylistItemInterval,
  removePlaylistItemIntervalFail,
  removePlaylistItemIntervalSuccess,
  copyPlaylistSuccess,
  copyPlaylistFail,
  copyPlaylist,
  updatePlaylistItems,
  updatePlaylistItemsFail,
  deselectAllPlaylistItems,
  updatePlaylistItemsSuccess,
  updateRevolverItemSuccess,
  updateRevolverItemFail,
  updateRevolverItem,
  reorderItemsSuccess,
  updateUrlItemSuccess,
  updateUrlItemFail,
  updateUrlItem,
  updateProductItemsTemplateSuccess,
  updateProductItemsTemplateFail,
  updateProductItemsTemplate,
  duplicatePlaylistItem,
  duplicatePlaylistItemFail,
  duplicatePlaylistItemSuccess
} from '../actions/playlists'
import { selectSelectedPlaylistItemIds, selectSelectedPlaylist } from '../selectors/playlists'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'
import { closeDialog } from '../actions/dialogs'
import Api from '../services/api/playlists'
import Products from '../services/api/products'
import { selectMediaByKey } from '../selectors/media'
import i18n from '../translations/i18n'
import { checkUrlIsIframeCompatible } from '../services/api/url'
import { selectListById } from '../selectors/lists'
import { playlistDeletionFail } from '../utils/message'
import {
  AddProductFormData,
  isWeatherConditionFormData,
  isTagsConditionFormData,
  PageType
} from '../types/playlists'
import {
  handleCreatePlaylistFormParams,
  handleUpdatePlaylistFormParams,
  handleAddPlaylistItemsFormParams,
  handleSchedulePlaylistItemFormParams,
  handleIntervalPlaylistItemFormParams,
  handleAddPlaylistItemConditionFormParams,
  handleUpdateProductItemsTemplateParams,
  handleUpdateAddRevolverItemFormParams
} from '../types/formData'
import { sendGaEvent } from '../config/ga'
import { reorder } from '../utils/arrays'
import MediaApi from '../services/api/media'
import { KeyWithUrl } from '../types/media'
import { selectUserSub } from '../selectors/users'
import { selectInfopageById } from '../selectors/infopages'
import { selectContentById } from '../selectors/contents'
import { selectTemplateById } from '../selectors/templates'
import { calculateTotalDuration } from '../utils/playlists'
import { SelectedInfopageType } from '../types/infopages'
import { convertPlaylistItemScheduleFormValues } from '../utils/forms'
import { getInfopage, getInfopageSuccess } from '../actions/infopages'

interface HandleReorderItemsParams {
  payload: {
    startIndex: number
    endIndex: number
  }
}

export function* handleReorderItems({
  payload: { startIndex, endIndex }
}: HandleReorderItemsParams) {
  try {
    const playlist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    if (playlist?.items) {
      const reorderedPlaylist = {
        ...playlist,
        items: reorder(playlist?.items, startIndex, endIndex)
      }
      yield put(reorderItemsSuccess(reorderedPlaylist))
    }
  } catch (error) {
    yield put(reorderItemsFail(error.message))
  }
}

interface HandleListPlaylistsParams {
  payload?: {
    includeParentPlaylists?: boolean
  }
}

/**
 * List all environment playlists. If includeParentPlaylists is included in request payload,
 * request also parent playlists from API if environment has a parent environment.
 * @param param0
 */
export function* handleListPlaylists({ payload }: HandleListPlaylistsParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const playlistsArray: PlaylistUI[] = yield call(
        Api.getPlaylists,
        environmentId,
        payload?.includeParentPlaylists
      )
      yield put(listPlaylistsSuccess({ environmentId, playlistsArray }))
    } else {
      yield put(listPlaylistsFail(i18n.t('error.playlist.selectEnvFirst')))
    }
  } catch (error) {
    yield put(listPlaylistsFail(error.message))
  }
}

interface GetPlaylistParams {
  /** playlistId */
  payload: string
}

export function* handleGetPlaylist({ payload: playlistId }: GetPlaylistParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const playlist: PlaylistUI = yield call(Api.getPlaylist, environmentId, playlistId)
    const totalDuration = calculateTotalDuration(playlist.items, playlist.defaultInterval)
    playlist.totalDuration = totalDuration
    yield put(getPlaylistSuccess(playlist))
  } catch (error) {
    yield put(getPlaylistFail(error.message))
  }
}

export function* handleCreatePlaylist({
  payload: { formData, resolve, reject }
}: handleCreatePlaylistFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name, defaultInterval, permissionTags, syncPlay } = formData
    const tags = permissionTags && permissionTags.map(({ value }) => value)

    if (environmentId) {
      const playlist: PlaylistUI = yield call(Api.createPlaylist, environmentId, {
        name,
        defaultInterval,
        permissionTags: tags,
        syncPlay
      })
      yield put(createPlaylistSuccess(playlist))
      sendGaEvent('createPlaylistSuccess', {
        category: 'playlists'
      })
      yield put(closeDialog())
    }
    resolve()
  } catch (error) {
    yield put(createPlaylistFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.playlist.somethingWrongCreate')
      })
    )
  }
}

export function* handleUpdatePlaylist({
  payload: {
    formData: { name, defaultInterval, playlistId, permissionTags, syncPlay },
    resolve,
    reject
  }
}: handleUpdatePlaylistFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const tags =
      permissionTags && permissionTags.length ? permissionTags.map(({ value }) => value) : null
    const updatedPlaylist: PlaylistUI = yield call(Api.updatePlaylist, environmentId, playlistId, {
      name,
      defaultInterval,
      permissionTags: tags,
      syncPlay
    })
    yield put(updatePlaylistSuccess(updatedPlaylist))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(updatePlaylistFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.playlist.somethingWrongUpdate')
      })
    )
  }
}

function* generateProductPlaylistItem(environmentId: string, formData: AddProductFormData) {
  const { customerId, product, template } = formData
  const { templateId, templateEnvironmentId } = template
  const userSub: string = yield select(selectUserSub)
  if (isPharmacyCustomerProduct(product)) {
    const newProduct: PharmacyProduct = yield call(
      Products.updateCustomerDrug,
      environmentId,
      customerId.value,
      product
    )
    const item: ProductPlaylistItemUI = {
      productId: product.ean,
      templateId,
      templateEnvironmentId,
      customerId: customerId.value,
      type: PlaylistItemType.product,
      itemId: nanoid(),
      userSub,
      product: newProduct
    }
    return item
  } else {
    const { productId } = JSON.parse(product.value) // as string value in form

    const productData: GeneralRetailProduct = yield call(
      Products.getProductById,
      environmentId,
      customerId.value,
      ListType.retail,
      productId
    )

    const key = productData.key
    const media: KeyWithUrl | undefined = key
      ? yield call(MediaApi.getMediaUrl, key, true)
      : undefined

    const generalProductUI: GeneralProductUI = {
      ...productData
    }
    if (media) {
      const { key, url, thumbnailUrl } = media
      generalProductUI.productMedia = {
        key,
        url,
        thumbnailUrl
      }
    }

    const item: ProductPlaylistItemUI = {
      productId,
      templateId: templateId,
      templateEnvironmentId,
      customerId: customerId.value,
      type: PlaylistItemType.product,
      itemId: nanoid(),
      userSub,
      product: generalProductUI
    }

    return item
  }
}

function* convertExistingFileToPlaylistItem(existingFileKey: string, userSub: string) {
  const { key, type, name, quality, duration }: Media = yield select(
    selectMediaByKey(existingFileKey)
  )
  const { url }: KeyWithUrl = yield call(MediaApi.getMediaUrl, key)
  const newMediaPlaylistItem: MediaPlaylistItemUI = {
    itemId: nanoid(),
    key,
    name,
    type: type === MediaType.image ? PlaylistItemType.image : PlaylistItemType.video,
    url: url,
    userSub,
    quality,
    duration
  }
  return newMediaPlaylistItem
}

export function* addMultipleItems(
  environmentId: string,
  playlistId: string,
  items: MediaPlaylistItemUI[]
) {
  const newItems: PlaylistItemUI[] = yield call(
    Api.addMultiplePlaylistItems,
    environmentId,
    playlistId,
    items
  )
  yield all(newItems.map(item => put(addPlaylistItemSuccess(item))))
  return newItems
}

/**
 * Add playlist items
 */
export function* handleAddPlaylistItems({
  payload: { formData, resolve, reject }
}: handleAddPlaylistItemsFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const userSub: string = yield select(selectUserSub)
    switch (formData.type) {
      case PlaylistItemType.product: {
        if (!formData.product) {
          throw new SubmissionError({ _error: i18n.t('error.playlist.selectProduct') })
        }
        /** adding existing general product to playlist */
        const item: ProductPlaylistItemUI = yield call(
          generateProductPlaylistItem,
          environmentId,
          formData
        )
        const template: Template = yield select(selectTemplateById(item.templateId))
        item.interval = template?.duration || undefined
        yield put(addPlaylistItemSuccess(item))
        break
      }
      case PlaylistItemType.url: {
        const { url, interval } = formData
        const item: UrlPlaylistItem = {
          url,
          type: PlaylistItemType.url,
          interval,
          itemId: nanoid(),
          userSub
        }
        yield put(addPlaylistItemSuccess(item))
        break
      }
      case PlaylistItemType.list: {
        const {
          list: { value }
        } = formData
        const list: ListUI = yield select(selectListById(value))
        const item: ListPlaylistItemUI = {
          list,
          listId: list.listId,
          // template: list.template,
          type: PlaylistItemType.list,
          itemId: nanoid(),
          userSub
        }
        yield put(addPlaylistItemSuccess(item))
        break
      }
      case PageType.media: {
        const { existingFiles } = formData
        if (existingFiles === undefined || !existingFiles.length) {
          throw new SubmissionError({
            _error: i18n.t('error.media.fileNotSelected')
          })
        }
        if (Array.isArray(existingFiles)) {
          const userSub: string = yield select(selectUserSub)
          const existingMediaPlaylistItems: MediaPlaylistItemUI[] = yield all(
            existingFiles.map(existingFile =>
              convertExistingFileToPlaylistItem(existingFile, userSub)
            )
          )
          yield put(addPlaylistItemsSuccess(existingMediaPlaylistItems))
        }
        break
      }
      case PlaylistItemType.infopage: {
        const {
          infopage: { id, type }
        } = formData
        if (type === SelectedInfopageType.infopage) {
          // note: listInfopages action lists infopages without contentMetadata
          // so we need to fetch infopage in order to get InfopageExtensive response
          // otherwise, editing infopage from playlist view does not work.

          const infopage: Infopage = yield select(selectInfopageById(id))
          yield put(getInfopage(id))
          yield take([getInfopageSuccess]) // wait until infopage is fetched
          const infopageExtensive: InfopageExtensive = yield select(selectInfopageById(id))
          const template: Template = yield select(selectTemplateById(infopage?.templateId))
          const item: InfopagePlaylistItemUI = {
            itemId: nanoid(),
            infopageId: id,
            type: PlaylistItemType.infopage,
            infopage: {
              ...infopageExtensive,
              thumbnailUrl: infopage.thumbnailUrl, // thunbnail exist only in listInfopages response
              template
            },
            userSub
          }
          item.interval = template?.duration || undefined
          yield put(addPlaylistItemSuccess(item))
        } else {
          const content: ContentUI = yield select(selectContentById(id))
          const item: ContentPlaylistItemUI = {
            itemId: nanoid(),
            contentId: id,
            type: PlaylistItemType.content,
            userSub,
            content
          }
          yield put(addPlaylistItemSuccess(item))
        }
        break
      }
      case PlaylistItemType.content: {
        break
      }
      case PlaylistItemType.revolver: {
        const { revolverItems, name } = formData
        const item: RevolverPlaylistItemUI = {
          itemId: nanoid(),
          type: PlaylistItemType.revolver,
          revolverItems,
          name,
          userSub
        }
        yield put(addPlaylistItemSuccess(item))
        break
      }
      default:
        throw new SubmissionError({
          _error: i18n.t('error.playlist.somethingWrongAddItem')
        })
    }
    yield put(closeDialog())
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.playlist.somethingWrongAddItem')
        })
      )
    }
    yield put(addPlaylistItemsFail(error.message))
  }
}

interface HandleDeletePlaylistParams {
  payload: string
}

export function* handleDeletePlaylist({ payload }: HandleDeletePlaylistParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.deletePlaylist, environmentId, payload)
    yield put(deletePlaylistSuccess(payload))
    yield put(closeDialog())
  } catch (error) {
    const screens: string[] | undefined = path(['response', 'data', 'screens'], error)
    const contents: string[] | undefined = path(['response', 'data', 'contents'], error)
    const errorMessage = playlistDeletionFail({ screens, contents })
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deletePlaylistFail(error.message))
  }
}

export function* handleDeletePlaylistItem() {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    yield all(itemIds.map(itemId => put(deletePlaylistItemSuccess(itemId))))
    yield put(deselectAllPlaylistItems())
  } catch (error) {
    yield put(deletePlaylistItemFail(error.message))
  }
}

export function* handleHidePlaylistItem() {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    yield all(itemIds.map(itemId => put(hidePlaylistItemSuccess(itemId))))
  } catch (error) {
    yield put(hidePlaylistItemFail(error.message))
  }
}

export function* handleDuplicatePlaylistItem() {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    const playlist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    if (playlist) {
      const updatedPlaylistItems = playlist.items.reduce((playlistItems, playlistItem) => {
        playlistItems.push(playlistItem)
        if (itemIds.includes(playlistItem.itemId)) {
          //  if playlist item is selected, duplicate item
          const duplicatedPlaylistItem: PlaylistItemUI = { ...playlistItem, itemId: nanoid() }
          playlistItems.push(duplicatedPlaylistItem)
        }
        return playlistItems
      }, [] as PlaylistItemUI[])
      yield put(
        duplicatePlaylistItemSuccess({
          updatedItemsWithData: updatedPlaylistItems,
          totalDuration: calculateTotalDuration(updatedPlaylistItems, playlist.defaultInterval)
        })
      )
    }
  } catch (error) {
    yield put(duplicatePlaylistItemFail(error.message))
  }
}

export function* handleSchedulePlaylistItem({
  payload: { formData, resolve, reject }
}: handleSchedulePlaylistItemFormParams) {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    yield all(
      itemIds.map(itemId =>
        put(
          schedulePlaylistItemSuccess({
            itemId,
            schedule: convertPlaylistItemScheduleFormValues(formData)
          })
        )
      )
    )
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.playlist.somethingWrongSchedule')
      })
    )
    yield put(schedulePlaylistItemFail(error.message))
  }
}

export function* handleUpdateProductItemsTemplate({
  payload: {
    formData: { template: templateFormValue },
    resolve,
    reject
  }
}: handleUpdateProductItemsTemplateParams) {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    const template: Template = yield select(selectTemplateById(templateFormValue.templateId))
    yield all(
      itemIds.map(itemId =>
        put(
          updateProductItemsTemplateSuccess({
            itemId,
            template: templateFormValue,
            templateDuration: template.duration
          })
        )
      )
    )
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.playlist.somethingWrongUpdateTemplate')
      })
    )
    yield put(updateProductItemsTemplateFail(error.message))
  }
}

export function* handleIntervalPlaylistItem({
  payload: {
    formData: { interval },
    resolve,
    reject
  }
}: handleIntervalPlaylistItemFormParams) {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    const customInterval = interval === '' ? null : interval

    yield all(
      itemIds.map(itemId =>
        put(
          intervalPlaylistItemSuccess({
            itemId,
            interval: customInterval
          })
        )
      )
    )
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.playlist.somethingWrongInterval')
      })
    )
    yield put(intervalPlaylistItemFail(error.message))
  }
}

export function* handleRemovePlaylistItemInterval() {
  try {
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    yield all(itemIds.map(itemId => put(removePlaylistItemIntervalSuccess(itemId))))
    yield put(closeDialog())
  } catch (error) {
    toast.error(i18n.t('error.playlist.removeInterval'))
    yield put(removePlaylistItemIntervalFail(error.message))
  }
}

export function* handleAddPlaylistItemCondition({
  payload: { formData, resolve, reject }
}: handleAddPlaylistItemConditionFormParams) {
  try {
    const playlist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    const itemIds: string[] = yield select(selectSelectedPlaylistItemIds)
    if (playlist) {
      const selectedItems = playlist.items.filter(({ itemId }) => itemIds.includes(itemId))
      for (const { itemId, conditions } of selectedItems) {
        const updatedConditions: PlaylistItemConditions = {
          ...conditions
        }
        if (isWeatherConditionFormData(formData)) {
          if (formData.weather) {
            updatedConditions.weather = formData.weather
          } else {
            delete updatedConditions.weather
          }
        }
        if (isTagsConditionFormData(formData)) {
          // When only one 'PlaylistItemTagConditions' without tags, remove tag conditions.
          if (formData.tags.length === 1 && formData.tags[0].values.length === 0) {
            formData.tags = []
          }
          // filter out tags with empty values array
          updatedConditions.tags = formData.tags.filter(
            (tagGroup: PlaylistItemTagConditions) => tagGroup.values?.length
          ) as PlaylistItemTagConditions[]
        }
        yield put(
          addPlaylistItemConditionSuccess({
            itemId,
            conditions: updatedConditions
          })
        )
      }
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: error.message
        })
      )
    }
    yield put(addPlaylistItemConditionFail(error.message))
  }
}

interface CheckUrlIsValidRejection {
  url: string
}

interface HandleCheckUrlIsValidParams {
  payload: {
    url: string
    resolve: () => void
    reject: (error: CheckUrlIsValidRejection) => void
  }
}

export function* handleCheckUrlIsValid({
  payload: { url, resolve, reject }
}: HandleCheckUrlIsValidParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(checkUrlIsIframeCompatible, environmentId, url)
    resolve()
  } catch (error) {
    yield call(reject, { url: i18n.t('error.playlist.urlItem') })
  }
}

interface HandleCopyPlaylistParams {
  payload: string
}

export function* handleCopyPlaylist({ payload }: HandleCopyPlaylistParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const playlist: PlaylistUI = yield call(Api.copyPlaylist, environmentId, payload)
    toast.success(i18n.t('playlists.actions.copySuccess', { name: `(${playlist.name})` }))
    yield put(copyPlaylistSuccess(playlist))
  } catch (error) {
    yield put(copyPlaylistFail(error.message))
  }
}

/**
 * Convert playlist item data to playlists-api compatible format
 */
const stripItemData = (item: PlaylistItemUI) => {
  const { itemId, schedule, conditions, isHidden, userSub } = item
  const commonItemProps = {
    itemId,
    schedule: isLegacySchedule(schedule) ? convertLegacySchedule(schedule) : schedule,
    conditions,
    isHidden,
    userSub
  }
  switch (item.type) {
    case PlaylistItemType.url: {
      const { interval, url, type } = item
      const urlItem: UrlPlaylistItem = {
        ...commonItemProps,
        interval,
        url,
        type
      }
      return urlItem
    }
    case PlaylistItemType.list: {
      const { listId, interval, type } = item
      const listItem: ListPlaylistItem = {
        ...commonItemProps,
        interval,
        listId,
        type
      }
      return listItem
    }
    case PlaylistItemType.product: {
      const { customerId, productId, templateId, templateEnvironmentId, interval, type } = item
      const productItem: ProductPlaylistItem = {
        ...commonItemProps,
        interval,
        customerId,
        productId,
        templateId,
        templateEnvironmentId,
        type
      }
      return productItem
    }
    case PlaylistItemType.infopage: {
      const { templateId, infopageId, interval, type } = item
      const infopageItem: InfopagePlaylistItem = {
        ...commonItemProps,
        interval,
        templateId,
        infopageId,
        type
      }
      return infopageItem
    }
    case PlaylistItemType.content: {
      const { contentId, interval, type } = item
      const contentItem: ContentPlaylistItem = {
        ...commonItemProps,
        interval,
        contentId,
        type
      }
      return contentItem
    }
    case PlaylistItemType.revolver: {
      const { revolverItems: extendedItems, name, type } = item
      const revolverItems = extendedItems.map(({ itemId, key }) => ({ itemId, key }))
      const revolverItem: RevolverPlaylistItem = {
        ...commonItemProps,
        name,
        revolverItems,
        type
      }
      return revolverItem
    }
    case PlaylistItemType.image:
    case PlaylistItemType.video: {
      const { key, type } = item
      const { interval } = item as ImagePlaylistItem
      const mediaItem: MediaPlaylistItem = {
        ...commonItemProps,
        interval,
        key,
        type
      }
      return mediaItem
    }
    default:
      return item
  }
}

export function* handleUpdatePlaylistItems() {
  const environmentId: string = yield select(selectEnvironmentIdFromPathname)
  try {
    const { items, totalDuration, playlistId }: PlaylistUI = yield select(selectSelectedPlaylist)
    const itemsWithStrippedData = items.map(item => stripItemData(item))
    const playlist: PlaylistUI = yield call(
      Api.updatePlaylistItems,
      environmentId,
      playlistId,
      itemsWithStrippedData,
      totalDuration
    )
    const updatedItemsWithData = items.map(item => {
      const playlistItem = playlist.items.find(({ itemId }) => itemId === item.itemId)
      if (item.type === PlaylistItemType.revolver) {
        return {
          ...item,
          ...playlistItem,
          revolverItems: item.revolverItems
        }
      }
      return {
        ...item,
        ...playlistItem
      }
    })

    const playlistResponse: PlaylistUI = {
      ...playlist,
      items: updatedItemsWithData,
      totalDuration
    }

    yield put(deselectAllPlaylistItems())
    yield put(updatePlaylistItemsSuccess(playlistResponse))
    toast.success(i18n.t('lists.changesSaved'))
    sendGaEvent('updatePlaylistItemsSuccess', {
      category: 'playlists',
      label: 'items count',
      value: items.length
    })
  } catch (error) {
    toast.error(i18n.t('error.playlist.somethingWrongUpdate'))
    yield put(addMediaFail())
    yield put(updatePlaylistItemsFail(error.message))
  }
}

export function* handleUpdateRevolverItem({
  payload: { formData, resolve, reject }
}: handleUpdateAddRevolverItemFormParams) {
  try {
    yield put(updateRevolverItemSuccess(formData))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(updateRevolverItemFail(error.message))
    reject(error)
  }
}

export function* handleUpdateUrlItem({ payload: { formData, resolve, reject } }: any) {
  try {
    yield put(updateUrlItemSuccess(formData))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(updateUrlItemFail(error.message))
    reject(error)
  }
}

function* watchPlaylistsActions() {
  yield all([
    takeLatest(reorderItems, handleReorderItems),
    takeLatest(listPlaylists, handleListPlaylists),
    takeLatest(updatePlaylist, handleUpdatePlaylist),
    takeLatest(createPlaylist, handleCreatePlaylist),
    takeLatest(addPlaylistItems, handleAddPlaylistItems),
    takeLatest(getPlaylist, handleGetPlaylist),
    takeLatest(deletePlaylist, handleDeletePlaylist),
    takeLatest(deletePlaylistItem, handleDeletePlaylistItem),
    takeLatest(hidePlaylistItem, handleHidePlaylistItem),
    takeLatest(duplicatePlaylistItem, handleDuplicatePlaylistItem),
    takeLatest(schedulePlaylistItem, handleSchedulePlaylistItem),
    takeLatest(updateProductItemsTemplate, handleUpdateProductItemsTemplate),
    takeLatest(intervalPlaylistItem, handleIntervalPlaylistItem),
    takeLatest(removePlaylistItemInterval, handleRemovePlaylistItemInterval),
    takeLatest(addPlaylistItemCondition, handleAddPlaylistItemCondition),
    takeLatest(checkUrlIsValid, handleCheckUrlIsValid),
    takeLatest(copyPlaylist, handleCopyPlaylist),
    takeLatest(updatePlaylistItems, handleUpdatePlaylistItems),
    takeLatest(updateRevolverItem, handleUpdateRevolverItem),
    takeLatest(updateUrlItem, handleUpdateUrlItem)
  ])
}

export default [watchPlaylistsActions]
