import { Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { TFunction } from 'i18next'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../../actions/dialogs'
import { listProductsFromForm } from '../../../actions/products'
import {
  selectEditableProductCustomersAsOptions,
  selectMenuOrRetailTables
} from '../../../selectors/customers'
import { ReduxForm } from '../../../types/forms'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { required } from '../../../validation'
import {
  ReduxTextField,
  ReduxSelectWithAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'

interface SelectCustomerFormProps extends WithTranslation, ReduxForm {
  getCustomerProducts: (formData?: any) => void
  customers: AutocompleteOption[]
  closeDialog: CloseDialog
  dialogId: string
  customerTables: string[]
}

const types = (tables: string[], t: TFunction) =>
  tables.map(table => ({
    value: table,
    label: t(`customers.tableType.${table}`)
  }))

const SelectCustomerForm: React.FC<SelectCustomerFormProps> = ({
  dialogId,
  customerTables,
  customers,
  getCustomerProducts,
  closeDialog,
  handleSubmit,
  error,
  submitting,
  t
}) => (
  <form onSubmit={handleSubmit(getCustomerProducts)}>
    <Field
      name='customerId'
      label={t('product.customer')}
      component={ReduxSelectWithAutocomplete}
      options={customers}
      required
      validate={[required]}
    />
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      {customerTables && (
        <Grid item xs={12}>
          <Field
            variant='outlined'
            fullWidth
            name='type'
            label={t('lists.type')}
            component={ReduxTextField}
            select
            required
            validate={[required]}>
            {types(customerTables, t).map(({ value, label }: any) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      )}
    </Grid>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions>
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {t('general.ok')}
      </Button>
    </DialogActions>
  </form>
)

const mapStateToProps = (state: any) => {
  const selectedCustomer = formValueSelector('SelectCustomerForm')(state, 'customerId')
  return {
    selectedCustomer,
    customers: selectEditableProductCustomersAsOptions(state),
    customerTables: selectMenuOrRetailTables(selectedCustomer)(state)
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  getCustomerProducts: bindSubmitActionToPromise(dispatch, listProductsFromForm),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'SelectCustomerForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(withTranslation()(SelectCustomerForm) as any)
)
