import { connect } from 'react-redux'
import React, { Fragment, useState } from 'react'
import { LinearProgress, Grid, MenuItem } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ListUI } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../actions/dialogs'
import {
  selectListsAsArray,
  selectGetListsIsLoading,
  selectListSearchTerm,
  selectListsBySearchTerm
} from '../../selectors/lists'
import { navigate } from '../../actions/routes'
import { updateList, deleteList, copyList, navigateToLunchList } from '../../actions/lists'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { compareBySortType, compareStrings } from '../../utils/sorting'
import { selectCustomer } from '../../actions/customers'
import {
  selectCurrentEnvironmentFeatures,
  selectEnvironmentTemplateTypes
} from '../../selectors/environments'
import { selectUser, selectIsUserDeviceMobile } from '../../selectors/users'
import { Navigate, CloseDialog, NavigateToLunchList } from '../../types/actions'
import { StateInterface, IndexById } from '../../types/states'
import { selectTemplates } from '../../selectors/templates'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { StateTemplate } from '../../types/templates'
import { isListsFeatureEnabled } from '../../utils/lists'
import Sorter from '../../components/Sorting'
import { SortByValue, SortDirection } from '../../types/sortings'
import ListCard from './ListCard'
import ListToolbar from './ListToolbar'

const listStyles = () => ({
  lists: {
    padding: '10px 0px 10px',
    marginBottom: 100 // better mobile usability
  }
})

interface StateProps {
  allLists: ListUI[]
  isLoading: boolean
  isMobile: boolean
  searchTerm?: string
  listsBySearch: ListUI[]
  templatesById: IndexById<StateTemplate>
  environmentIdFromPath: string
  showLists: boolean
}

interface DispatchProps {
  closeDialog: CloseDialog
  navigate: Navigate
  navigateToLunchList: NavigateToLunchList
  updateList: (formData?: any) => void
  deleteList: (listId: string) => void
  copyList: (listId: string) => void
  selectCustomer: (customerId: string) => void
}

type ListsProps = StateProps & DispatchProps & WithStyles<typeof listStyles> & WithTranslation

const Lists: React.FC<ListsProps> = ({
  classes,
  allLists,
  navigate,
  navigateToLunchList,
  closeDialog,
  updateList,
  deleteList,
  copyList,
  isLoading,
  isMobile,
  searchTerm,
  showLists,
  listsBySearch,
  templatesById,
  environmentIdFromPath,
  t
}) => {
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.name)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)

  const visibleLists = searchTerm ? listsBySearch : allLists
  const lists = visibleLists.sort(compareBySortType(sortBy, sortDirection))

  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  return showLists ? (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.lists} />
      <Fragment>
        <ListToolbar />
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Fragment>
            <Sorter {...sorterProps}>
              <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
              <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
              <MenuItem value='updatedAt'>{t('sorting.sortBy.updatedAt')}</MenuItem>
            </Sorter>
            <Grid
              container
              className={classes.lists}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent={isMobile ? 'center' : 'flex-start'}>
              {lists.length > 0 && (
                <Fragment>
                  {lists.map(list => {
                    const cardProps = {
                      list,
                      navigate,
                      navigateToLunchList,
                      updateList,
                      deleteList,
                      copyList,
                      closeDialog,
                      template: templatesById[list.templateId],
                      environmentIdFromPath,
                      t
                    }
                    return <ListCard key={list.listId} {...cardProps} />
                  })}
                </Fragment>
              )}
            </Grid>
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  ) : (
    <Fragment />
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const searchTerm = selectListSearchTerm(state)
  const user = selectUser(state)
  const environmentFeatures = selectCurrentEnvironmentFeatures(state)
  const environmentTemplateTypes = selectEnvironmentTemplateTypes(state)
  return {
    allLists: selectListsAsArray(state).sort(compareStrings('name')),
    isLoading: selectGetListsIsLoading(state),
    isMobile: selectIsUserDeviceMobile(state),
    searchTerm,
    listsBySearch: selectListsBySearchTerm(searchTerm)(state).sort(compareStrings('name')),
    templatesById: selectTemplates(state),
    environmentIdFromPath: selectEnvironmentIdFromPathname(state) as string,
    showLists: isListsFeatureEnabled(user, environmentFeatures, environmentTemplateTypes)
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  navigate: (path: string) => dispatch(navigate(path)),
  navigateToLunchList: (params: any) => dispatch(navigateToLunchList(params)),
  updateList: bindSubmitActionToPromise(dispatch, updateList),
  deleteList: (listId: string) => dispatch(deleteList(listId)),
  copyList: (listId: string) => dispatch(copyList(listId)),
  selectCustomer: (customerId: string) => dispatch(selectCustomer(customerId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(listStyles)(withTranslation()(Lists)))
