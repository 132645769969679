import React from 'react'
import {
  PlaylistItemUI,
  isRevolverPlaylistItem,
  PlaylistItemType,
  isPlaylistItemWithIntervalAbility
} from '@seesignage/seesignage-utils'
import ItemDuration from '../Cards/ItemDuration'
import {
  ProductAsItem,
  ListAsItem,
  UrlAsItem,
  MediaAsItem,
  InfopageAsItem,
  RevolverAsItem,
  ContentAsItem
} from '../Cards'
import { PlaylistItemDuration } from '../../../../../types/playlists'

interface RenderItemCardProps {
  item: PlaylistItemUI
  isItemSelected: boolean
  isMobile: boolean
  itemPercentage: string
  showPercentage: boolean
}

const renderItemCard = ({
  item,
  isItemSelected,
  isMobile,
  itemPercentage,
  showPercentage
}: RenderItemCardProps) => {
  const itemProps = {
    isItemSelected,
    itemPercentage,
    showPercentage
  }
  switch (item.type) {
    case PlaylistItemType.video:
    case PlaylistItemType.image: {
      return <MediaAsItem item={item} {...itemProps} />
    }
    case PlaylistItemType.product: {
      return <ProductAsItem item={item} {...itemProps} />
    }
    case PlaylistItemType.content: {
      return <ContentAsItem item={item} {...itemProps} />
    }
    case PlaylistItemType.url: {
      return <UrlAsItem item={item} isMobile={isMobile} {...itemProps} />
    }
    case PlaylistItemType.list: {
      return <ListAsItem item={item} {...itemProps} />
    }
    case PlaylistItemType.infopage: {
      return <InfopageAsItem item={item} isMobile={isMobile} {...itemProps} />
    }
    default:
      return <div />
  }
}

interface PlaylistItemProps {
  item: PlaylistItemUI
  isItemSelected: boolean
  selectPlaylistItem: (itemId: string) => void
  deselectPlaylistItem: (itemId: string) => void
  isMobile: boolean
  itemDuration: PlaylistItemDuration
  showPercentage: boolean
  userHasWriteAccess: boolean
}

const PlaylistItemCard = ({
  item,
  isItemSelected,
  selectPlaylistItem,
  deselectPlaylistItem,
  isMobile,
  itemDuration,
  showPercentage,
  userHasWriteAccess
}: PlaylistItemProps) => {
  const hideItemDuration =
    item.isHidden || (isPlaylistItemWithIntervalAbility(item) && item.interval)
  // revolver item has own onClick handlers
  if (isRevolverPlaylistItem(item)) {
    const revolverItemProps = {
      item,
      isItemSelected,
      selectPlaylistItem,
      deselectPlaylistItem,
      isMobile,
      itemPercentage: itemDuration.percentageText,
      showPercentage
    }
    return (
      <div style={{ position: 'relative' }}>
        <RevolverAsItem {...revolverItemProps} />
        {!hideItemDuration && <ItemDuration itemDuration={itemDuration} />}
      </div>
    )
  } else {
    const { itemId } = item

    return (
      <div
        style={{ position: 'relative' }}
        onClick={() => {
          if (userHasWriteAccess) {
            isItemSelected ? deselectPlaylistItem(itemId) : selectPlaylistItem(itemId)
          }
        }}>
        {renderItemCard({
          item,
          isItemSelected,
          isMobile,
          itemPercentage: itemDuration.percentageText,
          showPercentage
        })}
        {!hideItemDuration && <ItemDuration itemDuration={itemDuration} />}
      </div>
    )
  }
}

export default PlaylistItemCard
