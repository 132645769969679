import { grey } from '@mui/material/colors'
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles'
import { fiFI } from '@mui/x-date-pickers/locales'
import { fiFI as DataGridFI } from '@mui/x-data-grid'
import { Language } from '@seesignage/seesignage-utils'

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
  }
}

declare module '@mui/material' {
  interface Color {
    main: string
    dark: string
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme()

const themeOptions: ThemeOptions = {
  palette: {
    grey: {
      main: grey[300],
      dark: grey[400]
    },
    primary: {
      light: 'rgb(145, 225, 255)',
      main: '#1e88e5',
      dark: '#005cb2',
      contrastText: '#fafafa'
    },
    secondary: {
      main: '#f50057'
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'grey' },
          style: {
            color: defaultTheme.palette.getContrastText(grey[300])
          }
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&:hover': {
              backgroundColor: 'rgb(171, 0, 60)'
            }
          }
        }
      ]
    }
  }
}

const getThemeWithLanguage = (language: Language) =>
  language === Language.fi
    ? createTheme(defaultTheme, themeOptions, fiFI, DataGridFI)
    : createTheme(defaultTheme, themeOptions)

export { getThemeWithLanguage }
