/* eslint-disable @typescript-eslint/camelcase */
import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Paper } from '@mui/material'
import { getProofOfPlayFormInitialValues } from '../../../../utils/proofOfPlay'
import { selectSelectedEnvironment } from '../../../../selectors/environments'
import {
  selectProofOfPlayItems,
  selectProofOfPlayPlaylistItemResults
} from '../../../../selectors/analytics'
import { resetAnalytics } from '../../../../actions/analytics'
import ProofOfPlayForm from './Forms/ProofOfPlayForm'
import ProofOfPlayTable from './ProofOfPlayTable/ProofOfPlayTable'
import ProofOfPlayCharts from './Charts/ProofOfPlayCharts'
import ProofOfPlayPlaylistItemResultsTable from './ProofOfPlayTable/ProofOfPlayPlaylistItemResultsTable'

const useStyles = makeStyles(() => ({
  paper: {
    height: '470px'
  }
}))

const ProofOfPlay = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedEnvironment = useSelector(selectSelectedEnvironment)
  const items = useSelector(selectProofOfPlayItems)
  const playlistItemResults = useSelector(selectProofOfPlayPlaylistItemResults)

  useEffect(() => {
    return () => {
      dispatch(resetAnalytics())
    }
  }, [dispatch])

  return (
    <Grid container>
      <Grid item xs={12} paddingX={1} marginTop={1} marginBottom={1}>
        {selectedEnvironment && (
          <ProofOfPlayForm
            initialValues={getProofOfPlayFormInitialValues(selectedEnvironment.environmentId)}
            currentEnvironment={selectedEnvironment}
          />
        )}
      </Grid>

      {items && items.length > 0 && (
        <Fragment>
          <Grid item xs={playlistItemResults ? 7 : 12} paddingX={1}>
            <Paper className={classes.paper}>
              <ProofOfPlayCharts items={items} />
            </Paper>
          </Grid>
          {playlistItemResults && playlistItemResults.length > 0 && (
            <Grid item xs={5} paddingX={1}>
              <Paper className={classes.paper}>
                <ProofOfPlayPlaylistItemResultsTable items={playlistItemResults} />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} paddingX={1}>
            <ProofOfPlayTable items={items} />
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}

export default ProofOfPlay
