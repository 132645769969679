import {
  SwipeableDrawer,
  LinearProgress,
  ListItem,
  ListItemText,
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  ScreenResponse,
  ScreenWarning,
  Status,
  WarningPriority
} from '@seesignage/seesignage-utils'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridSortModel,
  GridSortDirection,
  GridRowId
} from '@mui/x-data-grid'
import CloudDownloadIcon from 'mdi-react/CloudDownloadIcon'
import { useTranslation } from 'react-i18next'
import {
  selectScreensWithWarningsAsArray,
  selectScreenByIdDashboard,
  selectListScreensDashboardIsLoading,
  selectIsAdminScreensCsvLoading
} from '../../../selectors/screens'
import { selectEnvironments } from '../../../selectors/environments'
import LastContactCell from '../../Screens/ScreensTable/LastContactCell'
import WarningPriorityCell from '../../Screens/ScreensTable/WarningPriorityCell'
import {
  deselectScreenDashboard as deselectScreenDashboardAction,
  updateScreenFromDashboard as updateScreenFromDashboardAction,
  listScreensWithWarnings as listScreensWithWarningsAction,
  getScreenFromDashboard as getScreenFromDashboardAction,
  downloadAdminScreensCsv as downloadAdminScreensCsvAction
} from '../../../actions/screens'
import { ScreenDataGrid } from '../../../types/screens'
import { countDaysSinceLastContact } from '../../../utils/screens'
import { selectLocationQueryParams } from '../../../selectors/routing'
import { navigate as navigateAction } from '../../../actions/routes'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import WarningsCell from './WarningsCell'
import ScreenDrawerContent from './ScreenDrawerContent'

const columns: GridColDef[] = [
  {
    field: 'environment',
    headerName: 'Environment',
    width: 170,
    renderCell: ({ value: { displayName, name } }) => (
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography fontSize='0.875rem'>{name}</Typography>}
          secondary={displayName}
        />
      </ListItem>
    )
  },
  {
    field: 'name',
    headerName: 'Screen',
    width: 150
  },
  {
    field: 'highestPriority',
    headerName: 'Priority',
    width: 140,
    type: 'number',
    renderCell: function renderCell(params: GridCellParams) {
      return <WarningPriorityCell priority={params.value as WarningPriority} />
    }
  },
  {
    field: 'statuses',
    headerName: 'Last contact',
    sortable: false,
    width: 160,
    renderCell: function renderCell(params: GridCellParams) {
      const lastStatusItem = (params.value as Status[]).slice(-1)[0]
      return (
        <LastContactCell
          screenStatus={(params.row as ScreenResponse).status}
          lastStatus={lastStatusItem}
        />
      )
    }
  },
  {
    field: 'daysSinceLastContact',
    headerName: 'Days from contact',
    width: 150,
    type: 'number'
  },
  {
    field: 'warnings',
    headerName: 'Warnings',
    sortable: false,
    width: 160,
    renderCell: function renderCell(params: GridCellParams) {
      return <WarningsCell warnings={params.value as ScreenWarning[]} />
    }
  },
  {
    field: 'productNumber',
    headerName: 'Product',
    width: 150
  },
  {
    field: 'playerVersion',
    headerName: 'Version',
    width: 150
  }
]

interface PaginationModel {
  page: number
  pageSize: number
}

const ScreensWarnings: React.FC = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const updateScreenFromDashboard = bindSubmitActionToPromise(
    dispatch,
    updateScreenFromDashboardAction
  )
  const deselectScreenDashboard = () => dispatch(deselectScreenDashboardAction())
  const getScreensForDashboard = () => dispatch(listScreensWithWarningsAction())
  const getScreenFromDashboard = (ids: any) => dispatch(getScreenFromDashboardAction(ids))
  const navigate = (path: string) => dispatch(navigateAction(path))
  const downloadAdminScreens = () => dispatch(downloadAdminScreensCsvAction())

  const environments = useSelector(selectEnvironments)
  const currentTimeMs = Date.now()
  const qps = useSelector(selectLocationQueryParams)
  const pathScreenId = qps?.screenId
  const pathEnvironmentId = qps?.environmentId
  const isLoading = useSelector(selectListScreensDashboardIsLoading)
  const selectedScreen = useSelector(selectScreenByIdDashboard(pathScreenId))
  const screens = useSelector(selectScreensWithWarningsAsArray).map(screen => {
    const priorities = screen.warnings?.map(({ priority }) => priority)
    return {
      ...screen,
      environment: {
        name: environments[screen.environmentId]?.name,
        displayName: environments[screen.environmentId]?.displayName
      },
      highestPriority: priorities ? Math.max(...priorities) : 1,
      daysSinceLastContact: countDaysSinceLastContact(screen.statuses, currentTimeMs)
    } as ScreenDataGrid
  })

  const csvLoading = useSelector(selectIsAdminScreensCsvLoading)

  const handleMountActions = () => {
    getScreensForDashboard()
  }

  useEffect(handleMountActions, [])

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'highestPriority',
      sort: 'desc' as GridSortDirection // sort by highest priority by default
    }
  ])

  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>({
    page: 0,
    pageSize: 25
  })

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  const handleCloseDrawer = () => {
    deselectScreenDashboard()
    history.replace({ search: undefined }) // remove queryparam
  }

  return (
    <Grid container rowGap={2} marginTop={2}>
      <Grid item xs={12} paddingX={1}>
        <Button
          onClick={() => (csvLoading ? undefined : downloadAdminScreens())}
          startIcon={
            csvLoading ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              <CloudDownloadIcon color='#1e88e5' />
            )
          }
          variant='outlined'>
          {csvLoading
            ? `${t('general.loading')}...`
            : t('dashboard.screens.downloadScreensButtonLabel')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {isLoading && <LinearProgress />}
        <DataGrid
          autoHeight
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel)
          }}
          onRowClick={({ row }) => {
            navigate(
              `/dashboard?screenId=${(row as ScreenDataGrid).screenId}&environmentId=${
                (row as ScreenDataGrid).environmentId
              }`
            )
          }}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={screens}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50, 100]}
          getRowId={row => row.screenId} // custom id
        />

        <SwipeableDrawer
          anchor='right'
          open={pathEnvironmentId && pathScreenId ? true : false}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOpen={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={handleCloseDrawer}
          disableSwipeToOpen>
          {selectedScreen && (
            <ScreenDrawerContent
              screen={selectedScreen}
              handleCloseDrawer={handleCloseDrawer}
              updateScreenFromDashboard={updateScreenFromDashboard}
              getScreenFromDashboard={getScreenFromDashboard}
            />
          )}
        </SwipeableDrawer>
      </Grid>
    </Grid>
  )
}

export default ScreensWarnings
