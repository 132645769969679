import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import PageTitle from '../../components/PageTitle'
import { selectIsListAllLoading } from '../../selectors/customers'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import CustomersTable from './CustomersTable'

const Customers: React.FC = () => {
  const isLoading = useSelector(selectIsListAllLoading)
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.integrations} />
      {isLoading && <LinearProgress />}
      <CustomersTable />
    </Fragment>
  )
}

export default Customers
