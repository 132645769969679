import { API } from 'aws-amplify'
import {
  UpdateScreen,
  ScreenResponse,
  Log,
  S3ScreensLogsMetadata,
  PlaylistItemPreviewPlayer,
  BatchUpdateScreenProperties,
  GetRssFeedResponse,
  GetRssFeedRequest,
  ValidateScreenCodesResponse,
  QueryProofOfPlayRequest,
  QueryProofOfPlayResponse,
  GetProofOfPlayOptionsResponse,
  CreateProofOfPlayReportRequest
} from '@seesignage/seesignage-utils'

const getScreens = async (environmentId: string, includeAllStatuses?: boolean) => {
  const options = includeAllStatuses
    ? {
        queryStringParameters: {
          includeAllStatuses: true
        }
      }
    : {}
  const screens = await API.get('screens', `/environments/${environmentId}/screens`, options)
  return screens
}

const getScreen = async (environmentId: string, screenId: string) => {
  const screen: ScreenResponse = await API.get(
    'screens',
    `/environments/${environmentId}/screens/${screenId}`,
    {}
  )
  return screen
}

/**
 * Get screen logs
 * @param environmentId
 * @param screenId
 * @param date ISO date string
 */
const getScreenLogs = async (environmentId: string, screenId: string, date: string) => {
  try {
    const logs: Log[][] = await API.get(
      'screens',
      `/environments/${environmentId}/screens/${screenId}/logs`,
      {
        queryStringParameters: {
          date
        }
      }
    )
    return logs
  } catch (error) {
    return []
  }
}

const getScreenLogsMetadata = async (environmentId: string, screenId: string) => {
  try {
    const logs: S3ScreensLogsMetadata[] = await API.get(
      'screens',
      `/environments/${environmentId}/screens/${screenId}/logs`,
      {
        queryStringParameters: {
          getMetadata: true
        }
      }
    )
    return logs
  } catch (error) {
    return []
  }
}

const deleteScreen = async (environmentId: string, screenId: string) => {
  await API.del('screens', `/environments/${environmentId}/screens/${screenId}`, {})
}

const createScreen = async (environmentId: string, screen: any) => {
  const newScreen = await API.post('screens', `/environments/${environmentId}/screens`, {
    body: screen
  })
  return newScreen
}

const getRssFeed = async (url: string, itemCount?: number) => {
  const queryStringParameters: GetRssFeedRequest = { url, itemCount }
  const rssFeed: GetRssFeedResponse = await API.get('screens', '/rss-feed', {
    queryStringParameters
  })
  return rssFeed
}

const updateScreen = async (environmentId: string, screenId: string, screen: UpdateScreen) => {
  const updatedAttributes = await API.put(
    'screens',
    `/environments/${environmentId}/screens/${screenId}`,
    { body: screen }
  )
  return updatedAttributes
}

const updateBatchScreens = async (
  environmentId: string,
  screenIds: string[],
  updatedData: BatchUpdateScreenProperties
) => {
  const updatedAttributes = await API.put('screens', `/environments/${environmentId}/batchUpdate`, {
    body: {
      screenIds,
      updatedData
    }
  })
  return updatedAttributes
}

const sendScreenCommand = async (
  environmentId: string,
  screenId: string,
  command: 'ping' | 'files' | 'deleteFiles' | 'restart'
) => {
  await API.post('screens', `/environments/${environmentId}/screens/${screenId}/command`, {
    body: { command }
  })
}

const fetchAdminScreensCsv = async () => {
  const csv = await API.get('screens', '/listAllScreens', {})
  return csv
}

const getScreenByCode = async (code: string): Promise<Screen | undefined> => {
  try {
    const screen = await API.get('screens', `/pda/${code}`, {})
    return screen
  } catch (error) {
    return undefined
  }
}

const listScreensWarnings = async () => {
  const screens = await API.get('screens', '/dashboard/listScreensWarnings', {})
  return screens
}

/**
 * Move screen to another environment
 * @param sourceEnvironmentId
 * @param sourceScreenId
 * @param destinationEnvironmentId
 */
const moveScreen = async (
  sourceEnvironmentId: string,
  sourceScreenId: string,
  destinationEnvironmentId: string
) => {
  await API.put('screens', `/environments/${sourceEnvironmentId}/screens/${sourceScreenId}/move`, {
    body: { destinationEnvironmentId }
  })
}

/**
 * Get preview player items. Note: contains only thumbnails for playlist items
 * @param sessionId
 */
const getPreviewPlayerItems = async (sessionId: string, date?: string) => {
  const options = date
    ? {
        queryStringParameters: {
          date
        }
      }
    : {}
  const items: PlaylistItemPreviewPlayer[] = await API.get(
    'screens',
    `/previewPlayer/${sessionId}/items`,
    options
  )
  return items
}

const validateScreenCodes = async (environmentId: string, screenCodes: string[]) => {
  const response: ValidateScreenCodesResponse = await API.put(
    'screens',
    `/environments/${environmentId}/validateScreenCodes`,
    { body: { screenCodes } }
  )
  return response
}

const queryProofOfPlay = async (environmentId: string, queryRequest: QueryProofOfPlayRequest) => {
  const response: QueryProofOfPlayResponse = await API.post(
    'screens',
    `/environments/${environmentId}/proofOfPlay`,
    {
      body: queryRequest
    }
  )
  return response
}

const getProofOfPlayOptions = async (
  environmentId: string,
  screenEnvironmentId: string,
  startDate: string,
  endDate: string
) => {
  const response: GetProofOfPlayOptionsResponse = await API.get(
    'screens',
    `/environments/${environmentId}/proofOfPlay`,
    {
      queryStringParameters: {
        startDate,
        endDate,
        screenEnvironmentId
      }
    }
  )
  return response
}

const createProofOfPlayReport = async (
  environmentId: string,
  queryRequest: CreateProofOfPlayReportRequest
) => {
  const { url }: { url: string } = await API.post(
    'screens',
    `/environments/${environmentId}/proofOfPlayReport`,
    {
      body: queryRequest
    }
  )
  return url
}

export default {
  getScreens,
  getScreen,
  getScreenLogs,
  getScreenLogsMetadata,
  deleteScreen,
  createScreen,
  updateScreen,
  updateBatchScreens,
  sendScreenCommand,
  fetchAdminScreensCsv,
  getScreenByCode,
  listScreensWarnings,
  moveScreen,
  getPreviewPlayerItems,
  getRssFeed,
  validateScreenCodes,
  queryProofOfPlay,
  getProofOfPlayOptions,
  createProofOfPlayReport
}
