import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  LinearProgress
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form'
import { Dispatch } from 'redux'
import {
  formatDecimalsToCurrency,
  PackageUnit,
  parseCurrencyStringToNumber,
  parseStringToNumber,
  ProductTags
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { prefillRetailProductForm } from '../../../actions/products'
import { selectIsUserDeviceMobile, isUserSystemAdmin } from '../../../selectors/users'
import { eanOrPlu, isPriceValid, required } from '../../../validation'
import { asyncValidateCreateRetailProductForm } from '../../../validation/asyncValidate'
import { CloseDialog } from '../../../types/actions'
import { StateInterface } from '../../../types/states'
import { ReduxTextField, ReduxCheckbox } from '../../../components/FormInput/ReduxWrappers'
import { selectIsPrefillLoading } from '../../../selectors/products'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  selectProductContainer: {
    marginTop: theme.spacing(2)
  },
  image: {
    maxHeight: 200,
    maxWidth: 200,
    objectFit: 'contain' as any
  },
  dialogActionsMobile: {
    justifyContent: 'center'
  }
}))

const productUnits = Object.values(PackageUnit)

const getSalesUnits = (t: TFunction) => [
  {
    label: '',
    value: ''
  },
  ...productUnits.map(unit => ({
    value: unit,
    label: t(`product.unit.${unit}`)
  }))
]

const packageUnits = [
  {
    label: '',
    value: ''
  },
  {
    label: 'g',
    value: 'g'
  },
  {
    label: 'kg',
    value: 'kg'
  },
  {
    label: 'l',
    value: 'l'
  },
  {
    label: 'ml',
    value: 'ml'
  }
]

/**
 * Render common retail product fields
 * @param prefix used in template editor's mock form
 */
export const renderRetailProductFields = (t: TFunction, prefix?: string) => {
  const namePrefix = prefix ? `${prefix}.` : ''
  return (
    <Fragment>
      <Grid item xs={12} sm={12}>
        <Field
          fullWidth
          name={`${namePrefix}name`}
          label={t('general.name')}
          component={ReduxTextField}
          required
          validate={[required]}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Field
          fullWidth
          name={`${namePrefix}country`}
          label={t('product.country')}
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={`${namePrefix}qualityClass`}
          label={t('product.class')}
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={`${namePrefix}scaleNumber`}
          label={t('product.scaleNumber')}
          component={ReduxTextField}
          validate={[isPriceValid]}
          type='number'
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Field
          fullWidth
          multiline
          maxRows='4'
          name={`${namePrefix}description`}
          label={t('product.description')}
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={5} sm={5}>
        <Field
          fullWidth
          name={`${namePrefix}price`}
          label={t('product.sellingPrice')}
          component={ReduxTextField}
          required
          validate={[isPriceValid, required]}
          type='number'
          InputProps={{
            inputProps: { min: 0, max: 10000, step: 0.01 },
            endAdornment: <InputAdornment position='end'>€</InputAdornment>
          }}
          format={formatDecimalsToCurrency}
          parse={parseCurrencyStringToNumber}
        />
      </Grid>
      <Grid item xs={5} sm={5}>
        <Field
          fullWidth
          name={`${namePrefix}unit`}
          label={t('product.sellingUnit')}
          component={ReduxTextField}
          select>
          {getSalesUnits(t).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={1} sm={1}>
        <Tooltip
          disableInteractive
          disableFocusListener
          enterTouchDelay={50}
          leaveTouchDelay={3000}
          title={t('product.sellingUnitTooltip')}>
          <div>
            <InformationOutlineIcon />
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={5} sm={5}>
        <Field
          fullWidth
          name={`${namePrefix}packageSize`}
          label={t('product.packageSize')}
          component={ReduxTextField}
          validate={[isPriceValid]}
          type='number'
          parse={parseStringToNumber}
        />
      </Grid>
      <Grid item xs={5} sm={5}>
        <Field
          fullWidth
          name={`${namePrefix}packageUnit`}
          label={t('product.packageUnit')}
          component={ReduxTextField}
          select>
          {packageUnits.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={1} sm={1}>
        <Tooltip
          disableInteractive
          disableFocusListener
          enterTouchDelay={50}
          leaveTouchDelay={3000}
          title={t('product.packageUnitTooltip')}>
          <div>
            <InformationOutlineIcon />
          </div>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant='standard'>
          <FormLabel component='legend'>{`${t('product.tags')} (${t(
            'general.optional'
          )})`}</FormLabel>
          <FormGroup row>
            {Object.values(ProductTags).map(tag => (
              <FormControlLabel
                key={tag}
                style={{ marginLeft: 11 }}
                label={t(`product.tag.${tag}`)}
                control={
                  <Field name={`${namePrefix}productTags.${tag}`} component={ReduxCheckbox} />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
        <Typography display='block' variant='caption'>
          {t('product.selectOneTag')}
        </Typography>
      </Grid>
    </Fragment>
  )
}

interface OwnProps {
  closeDialog: CloseDialog
  onSubmit: (formData: any) => void
  submitButtonLabel: string
  dialogId: string
  customerId?: string
  disableProductIdField?: boolean
}

interface StateProps {
  isMobile: boolean
  isSystemAdmin: boolean
  imageUrl?: string
  isPrefilled?: boolean
  isPrefillLoading: boolean
}

interface DispatchProps {
  prefillRetailProductForm: (productId: string) => void
}

type CreateRetailProductFormProps = OwnProps &
  StateProps &
  DispatchProps &
  InjectedFormProps<{}, OwnProps & StateProps>

const CreateRetailProductForm: React.FC<CreateRetailProductFormProps> = ({
  handleSubmit,
  onSubmit,
  submitting,
  invalid,
  submitButtonLabel,
  closeDialog,
  dialogId,
  error,
  prefillRetailProductForm,
  imageUrl,
  isMobile,
  isSystemAdmin,
  disableProductIdField,
  isPrefilled,
  isPrefillLoading
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isSubmitDisabled = submitting || invalid
  return (
    <Fragment>
      {isPrefillLoading && <LinearProgress />}
      {imageUrl ? (
        <img src={imageUrl} className={classes.image} alt='' />
      ) : (
        <Typography variant='caption'>{t('product.imageNotFound')}</Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} sm={12}>
            <Field
              fullWidth
              name='productId'
              label={'EAN/PLU'}
              component={ReduxTextField}
              required
              validate={[required, eanOrPlu]}
              onChange={(e: any) => prefillRetailProductForm(e.target.value)}
              disabled={disableProductIdField}
            />
          </Grid>
          {isSystemAdmin && (
            <Grid item xs={12} sm={12}>
              <Field fullWidth name='imageUrl' label={t('image url')} component={ReduxTextField} />
            </Grid>
          )}
          {renderRetailProductFields(t)}
        </Grid>
        {isPrefilled && (
          <Grid item xs={12}>
            <Typography>{t('product.isPrefilled')}</Typography>
          </Grid>
        )}
        <Typography variant='body1' color='error'>
          {error || ''}
        </Typography>
        <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
          <Button onClick={() => closeDialog(dialogId)} color='primary'>
            {t('general.cancel')}
          </Button>
          <Button disabled={isSubmitDisabled} color='primary' type='submit'>
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state),
  isSystemAdmin: isUserSystemAdmin(state),
  imageUrl: formValueSelector('CreateRetailProductForm')(state, 'imageUrl'),
  isPrefilled: formValueSelector('CreateRetailProductForm')(state, 'isPrefilled'),
  isPrefillLoading: selectIsPrefillLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  prefillRetailProductForm: (productId: string) =>
    dispatch(
      prefillRetailProductForm({ productId, form: 'CreateRetailProductForm', field: 'imageUrl' })
    )
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'CreateRetailProductForm',
    asyncValidate: asyncValidateCreateRetailProductForm,
    asyncBlurFields: ['productId']
  })(CreateRetailProductForm)
)
