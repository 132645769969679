import React from 'react'
import { makeStyles } from '@mui/styles'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridSortDirection,
  GridSortModel,
  GridValueFormatterParams,
  GridCellParams
} from '@mui/x-data-grid'
import {
  CountPlaysByAttribute,
  ProofOfPlayQueryResultRowByChannel,
  ProofOfPlayQueryResultRowByPlaylist,
  QueryProofOfPlayRequest,
  TimeInterval
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import dateFormat from 'dateformat'
import PoundBoxIcon from 'mdi-react/PoundBoxIcon'
import Tags from '../../../../../components/Chips/Tags'
import { selectProofOfPlayQuery } from '../../../../../selectors/analytics'
import ProofOfPlayTableToolbar from './ProofOfPlayTableToolbar'

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: '7px'
  }
}))

const getProofOfPlayTableColumns = ({
  query: { countPlaysByAttribute, interval },
  t
}: {
  query: QueryProofOfPlayRequest
  t: TFunction
}) =>
  [
    {
      field: 'date_time',
      headerName: t('analytics.proofOfPlay.tableColumn.date_time'),
      width: 200,
      valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
        dateFormat(new Date(value), interval === TimeInterval.day ? 'd.m.yyyy' : 'd.m.yyyy HH:MM')
    },
    ...(countPlaysByAttribute === CountPlaysByAttribute.playlist_id
      ? [
          {
            field: 'playlist_name',
            headerName: t('analytics.proofOfPlay.tableColumn.playlist_name'),
            sortable: true,
            minWidth: 300,
            flex: 1
          }
        ]
      : []),
    ...(countPlaysByAttribute === CountPlaysByAttribute.channel_id
      ? [
          {
            field: 'channel_name',
            headerName: t('analytics.proofOfPlay.tableColumn.channel_name'),
            sortable: true,
            minWidth: 300,
            flex: 1
          }
        ]
      : []),
    {
      field: 'screen_name',
      headerName: t('analytics.proofOfPlay.tableColumn.screen_name'),
      width: 300
    },
    {
      field: 'code',
      headerName: t('analytics.proofOfPlay.tableColumn.code'),
      width: 200
    },
    {
      field: 'screen_tags',
      headerName: t('analytics.proofOfPlay.tableColumn.screen_tags'),
      width: 300,
      renderCell: (params: GridCellParams) => {
        const tags = params.value as string[] | null
        return <Tags icon={PoundBoxIcon} tags={tags} color='primary' />
      }
    },
    {
      field: 'play_count',
      headerName: t('analytics.proofOfPlay.tableColumn.play_count'),
      width: 200,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => `${value}`
    },
    {
      field: 'total_duration',
      headerName: t('analytics.proofOfPlay.tableColumn.total_duration'),
      width: 200,
      type: 'number',
      // format beacuse we do not want numbers to be formatted with ',' and we need to conver ms to s.
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => `${Math.round(value / 1000)}`
    }
  ] as GridColDef[]

interface PaginationModel {
  page: number
  pageSize: number
}

const ProofOfPlayTable = ({
  items
}: {
  items: (ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel)[]
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const query = useSelector(selectProofOfPlayQuery)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'date_time',
      sort: 'asc' as GridSortDirection
    }
  ])
  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>({
    page: 0,
    pageSize: 100
  })
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  return (
    <div className={classes.tableContainer}>
      {query && (
        <Paper>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  code: false
                }
              }
            }}
            disableRowSelectionOnClick
            slots={{ toolbar: ProofOfPlayTableToolbar }}
            density='compact'
            autoHeight
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            sortModel={sortModel}
            onSortModelChange={model => setSortModel(model)}
            rows={items}
            columns={getProofOfPlayTableColumns({ query, t })}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(
              row: ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel
            ) => {
              if (query.countPlaysByAttribute === CountPlaysByAttribute.playlist_id) {
                return `${row.screen_id}_${
                  (row as ProofOfPlayQueryResultRowByPlaylist).playlist_id
                }_${row.date_time}_${row.total_duration}`
              }
              if (query.countPlaysByAttribute === CountPlaysByAttribute.channel_id) {
                return `${row.screen_id}_${
                  (row as ProofOfPlayQueryResultRowByChannel).channel_id
                }_${row.date_time}_${row.total_duration}`
              }
              return 'todo'
            }}
          />
        </Paper>
      )}
    </div>
  )
}

export default ProofOfPlayTable
