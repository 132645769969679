import { createAsyncActions, createAction } from './utils'

const reducer = 'ANALYTICS'

const resetAnalytics = createAction(reducer, 'RESET')

const {
  action: queryProofOfPlay,
  actionSuccess: queryProofOfPlaySuccess,
  actionFail: queryProofOfPlayFail
} = createAsyncActions(reducer, 'QUERY_PROOF_OF_PLAY')

const {
  action: getProofOfPlayOptions,
  actionSuccess: getProofOfPlayOptionsSuccess,
  actionFail: getProofOfPlayOptionsFail
} = createAsyncActions(reducer, 'GET_PROOF_OF_PLAY_OPTIONS')

const {
  action: createProofOfPlayReport,
  actionSuccess: createProofOfPlayReportSuccess,
  actionFail: createProofOfPlayReportFail
} = createAsyncActions(reducer, 'CREATE_PROOF_OF_PLAY_REPORT')

export {
  resetAnalytics,
  queryProofOfPlay,
  queryProofOfPlaySuccess,
  queryProofOfPlayFail,
  getProofOfPlayOptions,
  getProofOfPlayOptionsSuccess,
  getProofOfPlayOptionsFail,
  createProofOfPlayReport,
  createProofOfPlayReportSuccess,
  createProofOfPlayReportFail
}
