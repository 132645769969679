import { Table, TableBody, TablePagination } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { Customer, Environment } from '@seesignage/seesignage-utils'
import { selectAllCustomersAsArray, selectSelectedCustomerRow } from '../../selectors/customers'
import { EnhancedTableHead } from '../../components/Table'
import EmptyRows from '../../components/Table/EmptyRows'
import { countEmptyRows } from '../../utils/tables'
import { selectEnvironments } from '../../selectors/environments'
import { compareStrings, sortTable } from '../../utils/sorting'
import { SortDirection } from '../../types/sortings'
import CustomersTableRow, { CustomersTableRowProps } from './CustomersTableRow'
import CustomersToolbar, { CustomersToolbarOwnProps } from './CustomersToolbar'

const columnData = [
  { id: 'name', disablePadding: false, label: 'customers.customer', sortable: true },
  { id: 'environment', disablePadding: false, label: 'customers.environment', sortable: false },
  { id: 'productForm', disablePadding: false, label: 'customers.productForm', sortable: false },
  { id: 'integration', disablePadding: false, label: 'customers.integration', sortable: false },
  { id: 'images', disablePadding: false, label: 'customers.images', sortable: false },
  { id: 'tables', disablePadding: false, label: 'customers.tables', sortable: false }
]

const colSpan = columnData.length

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 70
  },
  table: {
    minWidth: 350
  },
  tableWrapper: {
    overflowX: 'auto' as any
  }
})

const CustomersTable = () => {
  const classes = useStyles()
  const customers = useSelector(selectAllCustomersAsArray)
  const environments = useSelector(selectEnvironments)
  const selectedCustomerRow = useSelector(selectSelectedCustomerRow)
  const [searchTerm, search] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState<number>(100)
  const [page, setPage] = useState<number>(0)
  const [order, setOrder] = useState<SortDirection>(SortDirection.asc)
  const [orderBy, setOrderBy] = useState('name')

  const handleSortRequest = (property: string) => {
    sortTable(property, order, orderBy, setOrder, setOrderBy)
  }

  const toolbarProps: CustomersToolbarOwnProps = {
    selectedCustomerId: selectedCustomerRow,
    searchTerm,
    search
  }

  const tableHeadProps = {
    columnData,
    order,
    orderBy,
    handleSortRequest
  }

  const visibleCustomers =
    searchTerm.length > 0
      ? customers.filter(({ name }) =>
          name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : customers

  return (
    <Fragment>
      <CustomersToolbar {...toolbarProps} />
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} size='small'>
            <EnhancedTableHead {...tableHeadProps} />
            <TableBody>
              {visibleCustomers
                .sort(compareStrings(orderBy, order))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer: Customer) => {
                  const environment = environments[customer.environmentId] as
                    | Environment
                    | undefined
                  if (environment) {
                    const rowProps: CustomersTableRowProps = {
                      selectedCustomerId: selectedCustomerRow,
                      environment,
                      customer
                    }
                    return <CustomersTableRow key={customer.customerId} {...rowProps} />
                  }
                  return <Fragment key={customer.customerId} />
                })}
              <EmptyRows
                emptyRows={countEmptyRows(rowsPerPage, customers.length, page)}
                colSpan={colSpan}
              />
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component='div'
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={visibleCustomers.length > 0 ? page : 0}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onPageChange={(event: any, page: number) => {
            setPage(page)
          }}
          onRowsPerPageChange={event => {
            setRowsPerPage(parseInt(event.target.value))
          }}
        />
      </div>
    </Fragment>
  )
}

export default CustomersTable
