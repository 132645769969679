import { AppBar, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import PlusIcon from 'mdi-react/PlusIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Customer, Environment, EnvironmentUI } from '@seesignage/seesignage-utils'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { toolbarStyles } from '../../styles/common'
import {
  createCustomer as createCustomerAction,
  deleteCustomer as deleteCustomerAction,
  updateCustomer as updateCustomerAction
} from '../../actions/customers'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { CloseDialog } from '../../types/actions'
import { selectCustomerByIdFromAllCustomers } from '../../selectors/customers'
import Dialog from '../Dialog'
import { StateInterface } from '../../types/states'
import { getCustomerFormInitialValues } from '../../utils/customers'
import { selectEnvironmentById } from '../../selectors/environments'
import SearchField from '../../components/SearchField'
import CreateCustomerForm from './Forms/CreateCustomerForm'
import DeleteCustomerForm from './Forms/DeleteCustomerForm'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  root: {
    paddingRight: theme.spacing()
  },
  highlight: {
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light
  }
}))

interface RenderActions {
  isSelected: boolean
  selectedCustomer?: Customer
  customerEnvironment?: Environment | EnvironmentUI
  createCustomer: (data: any) => void
  updateCustomer: (data: any) => void
  deleteCustomer: (data: any) => void
  closeDialog: CloseDialog
  t: TFunction
}

const renderActions = ({
  isSelected,
  selectedCustomer,
  customerEnvironment,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  closeDialog,
  t
}: RenderActions) => (
  <Fragment>
    {isSelected && selectedCustomer && customerEnvironment ? (
      <Fragment>
        <div key='update customer'>
          <Dialog
            dialogId='UpdateCustomerDialog'
            title={t('customers.updateCustomer')}
            tooltipTitle={t('customers.updateCustomer')}
            ButtonIcon={SettingsIcon}
            Content={
              <CreateCustomerForm
                isUpdate
                dialogId='UpdateCustomerDialog'
                submitAction={updateCustomer}
                closeDialog={closeDialog}
                initialValues={getCustomerFormInitialValues(selectedCustomer, customerEnvironment)}
              />
            }
          />
        </div>
        <Dialog
          dialogId='DeleteCustomerDialog'
          title={t('customers.delete')}
          tooltipTitle={t('customers.delete')}
          ButtonIcon={DeleteIcon}
          Content={
            <DeleteCustomerForm
              dialogId='DeleteCustomerDialog'
              name={selectedCustomer.name}
              deleteCustomer={deleteCustomer}
              closeDialog={closeDialog}
              initialValues={{
                environmentId: selectedCustomer.environmentId,
                customerId: selectedCustomer.customerId
              }}
            />
          }
        />
      </Fragment>
    ) : (
      <div key='create customer'>
        <Dialog
          dialogId='CreateCustomerDialog'
          title={t('customers.createCustomer')}
          tooltipTitle={t('customers.createCustomer')}
          ButtonIcon={PlusIcon}
          Content={
            <CreateCustomerForm
              dialogId='CreateCustomerDialog'
              submitAction={createCustomer}
              closeDialog={closeDialog}
            />
          }
        />
      </div>
    )}
  </Fragment>
)

export interface CustomersToolbarOwnProps {
  selectedCustomerId?: string
  searchTerm: string
  search: React.Dispatch<React.SetStateAction<string>>
}

type CustomersToolbarProps = CustomersToolbarOwnProps

const CustomersToolbar: React.FC<CustomersToolbarProps> = ({
  selectedCustomerId,
  searchTerm,
  search
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const selectedCustomer = useSelector((state: StateInterface) =>
    selectedCustomerId ? selectCustomerByIdFromAllCustomers(selectedCustomerId)(state) : undefined
  )
  const customerEnvironment = useSelector((state: StateInterface) =>
    selectedCustomer ? selectEnvironmentById(selectedCustomer.environmentId)(state) : undefined
  )
  const isSelected = !!selectedCustomerId

  const createCustomer = bindSubmitActionToPromise(dispatch, createCustomerAction)
  const updateCustomer = bindSubmitActionToPromise(dispatch, updateCustomerAction)
  const deleteCustomer = bindSubmitActionToPromise(dispatch, deleteCustomerAction)
  const closeDialog = (dialogId: string) => dispatch(closeDialog(dialogId))

  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: isSelected
        })}>
        <Fragment>
          <div className={classes.title}>
            {isSelected ? (
              <Typography color='inherit' variant='subtitle1'>
                {t('customers.customer')} ({selectedCustomer?.name})
              </Typography>
            ) : (
              <SearchField
                placeholder={t('general.search')}
                value={searchTerm}
                onChange={e => search(e.target.value)}
              />
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {renderActions({
              isSelected,
              selectedCustomer,
              customerEnvironment,
              createCustomer,
              updateCustomer,
              deleteCustomer,
              closeDialog,
              t
            })}
          </div>
        </Fragment>
      </Toolbar>
    </AppBar>
  )
}

export default CustomersToolbar
