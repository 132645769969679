import React, { Fragment } from 'react'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridSortDirection,
  GridSortModel,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import {
  PlaylistItemTagConditions,
  ProofOfPlayPlaylistItemQueryResultRow
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Typography } from '@mui/material'
import ItemTags from '../../../../Playlists/EditPlaylist/Items/Cards/ItemTags'

const getProofOfPlayTableColumns = ({ t }: { t: TFunction }) =>
  [
    {
      field: 'name',
      headerName: t('analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.name'),
      width: 250
    },
    {
      field: 'type',
      headerName: t('analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.type'),
      width: 100,
      valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
        t(`analytics.proofOfPlay.tables.playlistItemResultsTable.playlistItemTypes.${value}`)
    },
    {
      field: 'playlist_item_tags',
      headerName: t(
        'analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.playlist_item_tags'
      ),
      width: 50,
      renderCell: (params: GridCellParams) => {
        const tagValue = params.value as PlaylistItemTagConditions[] | null | undefined
        return tagValue ? <ItemTags tags={tagValue} t={t} isPreviewItem dense /> : null
      }
    },
    {
      field: 'avg_duration',
      headerName: t(
        'analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.avg_duration'
      ),
      width: 100,
      type: 'number',
      // format beacuse we do not want numbers to be formatted with ',' and we need to conver ms to s.
      valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
        `${convertMsToSeconds(value)}`
    },
    {
      field: 'play_count',
      headerName: t('analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.play_count'),
      width: 100,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => `${value}`
    },
    {
      field: 'total_duration',
      headerName: t(
        'analytics.proofOfPlay.tables.playlistItemResultsTable.tableColumn.total_duration'
      ),
      minWidth: 120,
      flex: 1,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
        `${convertMsToSeconds(value)}`
    }
  ] as GridColDef[]

const convertMsToSeconds = (milliseconds: number) => Math.floor(milliseconds / 1000)

interface PaginationModel {
  page: number
  pageSize: number
}

const ProofOfPlayPlaylistItemResultsTable = ({
  items
}: {
  items: ProofOfPlayPlaylistItemQueryResultRow[]
}) => {
  const [t] = useTranslation()
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection
    }
  ])
  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>({
    page: 0,
    pageSize: 25
  })
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  return (
    <Fragment>
      <div style={{ height: '40px', width: '100%' }}>
        <Typography sx={{ padding: '10px', fontSize: '14px' }}>
          {t('analytics.proofOfPlay.tables.playlistItemResultsTable.title')}
        </Typography>
      </div>
      <div style={{ height: '420px', width: '100%' }}>
        <DataGrid
          density='compact'
          // hideFooter
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel)
          }}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={items}
          columns={getProofOfPlayTableColumns({ t })}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50, 100]}
          getRowId={(row: ProofOfPlayPlaylistItemQueryResultRow) =>
            `${row.playlist_item_id}-${row.name}`
          }
          sx={{
            borderBottom: 'none'
          }}
        />
      </div>
    </Fragment>
  )
}

export default ProofOfPlayPlaylistItemResultsTable
