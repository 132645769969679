/* eslint-disable @typescript-eslint/camelcase */
import React, { Fragment, useMemo, useState, useEffect } from 'react'
import { LineChart } from '@mui/x-charts/LineChart'
import { CurveType } from '@mui/x-charts/models'
import dateFormat from 'dateformat'
import {
  CountPlaysByAttribute,
  ProofOfPlayQueryResultRowByChannel,
  ProofOfPlayQueryResultRowByPlaylist
} from '@seesignage/seesignage-utils'
import { formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import {
  Button,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import FilterVariantIcon from 'mdi-react/FilterVariantIcon'
import { useTranslation } from 'react-i18next'
import { PROOF_OF_PLAY_FORM_NAME } from '../Forms/ProofOfPlayForm'
import { ProofOfPlayChartType } from '../../../../../types/analytics'

const getChartTypes = ({
  countPlaysByAttribute
}: {
  countPlaysByAttribute?: CountPlaysByAttribute
}) => {
  if (countPlaysByAttribute === CountPlaysByAttribute.channel_id) {
    return [
      ProofOfPlayChartType.playsPerChannel,
      ProofOfPlayChartType.durationPerChannel,
      ProofOfPlayChartType.playsPerScreen,
      ProofOfPlayChartType.durationPerScreen
    ]
  }
  if (countPlaysByAttribute === CountPlaysByAttribute.playlist_id) {
    return [
      ProofOfPlayChartType.playsPerPlaylist,
      ProofOfPlayChartType.durationPerPlaylist,
      ProofOfPlayChartType.playsPerScreen,
      ProofOfPlayChartType.durationPerScreen
    ]
  }

  return []
}

// Types
type GroupedDataItem = {
  name: string
  data: Record<string, { total_play_count?: number; total_duration?: number }>
}

type GroupedData = Record<string, GroupedDataItem>

// Constants
const MAX_SELECTED_SERIES = 15

// Utility functions
const getGroupKeyAndName = (
  item: ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel,
  chartType: ProofOfPlayChartType,
  countPlaysByAttribute: CountPlaysByAttribute
): { groupKey: string; groupName: string } | null => {
  const isScreenChart =
    chartType === ProofOfPlayChartType.playsPerScreen ||
    chartType === ProofOfPlayChartType.durationPerScreen

  if (countPlaysByAttribute === CountPlaysByAttribute.channel_id) {
    if ('channel_id' in item) {
      return {
        groupKey: isScreenChart ? item.screen_id : item.channel_id,
        groupName: isScreenChart ? item.screen_name : item.channel_name
      }
    }
  } else if (countPlaysByAttribute === CountPlaysByAttribute.playlist_id) {
    if ('playlist_id' in item) {
      return {
        groupKey: isScreenChart ? item.screen_id : item.playlist_id,
        groupName: isScreenChart ? item.screen_name : item.playlist_name
      }
    }
  }
  return null
}

const isPlayCountChart = (chartType: ProofOfPlayChartType): boolean => {
  return [
    ProofOfPlayChartType.playsPerScreen,
    ProofOfPlayChartType.playsPerPlaylist,
    ProofOfPlayChartType.playsPerChannel
  ].includes(chartType)
}

const isDurationChart = (chartType: ProofOfPlayChartType): boolean => {
  return [
    ProofOfPlayChartType.durationPerScreen,
    ProofOfPlayChartType.durationPerPlaylist,
    ProofOfPlayChartType.durationPerChannel
  ].includes(chartType)
}

const formatDuration = (value: number | null): string => {
  if (value === null) {
    return '-'
  }
  const hours = Math.floor(value / 3600000)
  const minutes = Math.floor((value % 3600000) / 60000)
  return `${hours}h ${minutes}min`
}

const formatDurationAxis = (value: number | null): string => {
  if (value === null) {
    return '-'
  }
  const hours = (value / 3600000).toFixed(0)
  return `${hours}h`
}

const ProofOfPlayLineChart = ({
  items,
  countPlaysByAttribute
}: {
  items: (ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel)[]
  countPlaysByAttribute: CountPlaysByAttribute
}) => {
  const [t] = useTranslation()
  const startDate = useSelector(state =>
    formValueSelector(PROOF_OF_PLAY_FORM_NAME)(state, 'startDate')
  )
  const endDate = useSelector(state => formValueSelector(PROOF_OF_PLAY_FORM_NAME)(state, 'endDate'))
  const [chartType, setSelectedChartType] = useState<ProofOfPlayChartType>(
    countPlaysByAttribute === CountPlaysByAttribute.playlist_id
      ? ProofOfPlayChartType.playsPerPlaylist
      : ProofOfPlayChartType.playsPerChannel
  )

  // State for filter popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isFilterPopoverOpen = Boolean(anchorEl)

  const handleChartTypeChange = (event: SelectChangeEvent) => {
    setSelectedChartType(event.target.value as ProofOfPlayChartType)
  }

  // Extract all unique sorted timestamps for the x-axis
  const xAxisData = useMemo(
    () =>
      Array.from(new Set(items.map(item => item.date_time))).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      ),
    [items]
  )

  // Group data by screen_id or playlist_id based on chartType
  const groupedData = useMemo(
    () =>
      items.reduce<GroupedData>((acc, item) => {
        const dateKey = item.date_time
        const groupInfo = getGroupKeyAndName(item, chartType, countPlaysByAttribute)

        if (!groupInfo) {
          return acc
        }

        const { groupKey, groupName } = groupInfo

        if (!acc[groupKey]) {
          acc[groupKey] = { name: groupName, data: {} }
        }

        if (isPlayCountChart(chartType)) {
          acc[groupKey].data[dateKey] = {
            total_play_count: (acc[groupKey].data[dateKey]?.total_play_count ?? 0) + item.play_count
          }
        } else if (isDurationChart(chartType)) {
          acc[groupKey].data[dateKey] = {
            total_duration: (acc[groupKey].data[dateKey]?.total_duration ?? 0) + item.total_duration
          }
        }

        return acc
      }, {}),
    [items, chartType, countPlaysByAttribute]
  )

  const groupedDataKeys = Object.keys(groupedData)
  // State for series visibility filter
  const [visibleSeries, setVisibleSeries] = useState<Record<string, boolean>>(() => {
    // Initialize with up to MAX_SELECTED_SERIES visible
    return groupedDataKeys.reduce((acc, key, index) => {
      // Only preselect the first MAX_SELECTED_SERIES items
      acc[key] = index < MAX_SELECTED_SERIES
      return acc
    }, {} as Record<string, boolean>)
  })

  // Filter button click handler
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Close popover handler
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Toggle series visibility
  const handleToggleSeries = (key: string) => {
    setVisibleSeries(prev => {
      // If trying to enable and already at max, don't allow
      if (!prev[key] && Object.values(prev).filter(Boolean).length >= MAX_SELECTED_SERIES) {
        return prev
      }

      return {
        ...prev,
        [key]: !prev[key]
      }
    })
  }

  // Select/deselect all series
  const handleSelectAll = (selected: boolean) => {
    if (selected && groupedDataKeys.length > MAX_SELECTED_SERIES) {
      // If selecting all but there are more than MAX_SELECTED_SERIES,
      // only select the first MAX_SELECTED_SERIES
      const newVisibility = groupedDataKeys.reduce((acc, key, index) => {
        acc[key] = index < MAX_SELECTED_SERIES
        return acc
      }, {} as Record<string, boolean>)
      setVisibleSeries(newVisibility)
    } else {
      // Otherwise, set all to the selected value
      const newVisibility = groupedDataKeys.reduce((acc, key) => {
        acc[key] = selected
        return acc
      }, {} as Record<string, boolean>)
      setVisibleSeries(newVisibility)
    }
  }

  // Get filtered series for chart
  const filteredSeries = useMemo(() => {
    return groupedDataKeys
      .filter(key => visibleSeries[key])
      .map(groupKey => ({
        label: groupedData[groupKey].name,
        data: xAxisData.map(date => {
          if (isPlayCountChart(chartType)) {
            return groupedData[groupKey].data[date]?.total_play_count ?? null
          } else if (isDurationChart(chartType)) {
            return groupedData[groupKey].data[date]?.total_duration ?? null
          }
          return null
        }),
        valueFormatter: isDurationChart(chartType) ? formatDuration : undefined,
        curve: 'linear' as CurveType
      }))
  }, [groupedDataKeys, visibleSeries, groupedData, xAxisData, chartType])

  // Check if all or none are selected
  const allSelected = groupedDataKeys.every(key => visibleSeries[key])
  const noneSelected = groupedDataKeys.every(key => !visibleSeries[key])

  // Update visibility state when chart type changes
  useEffect(() => {
    // Reset visibility state when chart type changes
    const newVisibleSeries = Object.keys(groupedData).reduce((acc, key, index) => {
      // Only preselect the first MAX_SELECTED_SERIES items
      acc[key] = index < MAX_SELECTED_SERIES
      return acc
    }, {} as Record<string, boolean>)

    setVisibleSeries(newVisibleSeries)

    // Only depend on chartType to prevent infinite loops
  }, [chartType, groupedData])

  const chartTypes = getChartTypes({
    countPlaysByAttribute
  })

  // Calculate the number of selected series
  const selectedCount = useMemo(() => {
    return Object.values(visibleSeries).filter(Boolean).length
  }, [visibleSeries])

  return (
    <Fragment>
      <div style={{ height: '40px', width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Select
            value={chartType}
            onChange={handleChartTypeChange}
            variant='standard'
            disableUnderline
            sx={{
              padding: '10px',
              cursor: 'pointer',
              fontSize: '14px',
              '& .MuiSelect-select': { padding: 0 }
            }}>
            {chartTypes.map(chartType => (
              <MenuItem key={chartType} value={chartType}>
                {t(`analytics.proofOfPlay.charts.chartType.${chartType}`)}
              </MenuItem>
            ))}
          </Select>
          <Button startIcon={<FilterVariantIcon />} onClick={handleFilterClick} size='small'>
            {t('analytics.proofOfPlay.charts.filters.title')} ({selectedCount}/
            {groupedDataKeys.length})
          </Button>
        </Box>
      </div>
      <Popover
        open={isFilterPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box sx={{ width: 300, maxHeight: 600, overflow: 'auto' }}>
          <Box sx={{ p: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
            {selectedCount >= MAX_SELECTED_SERIES && (
              <Typography variant='caption' color='error' sx={{ display: 'block', mt: 0.5 }}>
                {t('analytics.proofOfPlay.charts.filters.maxSelectedSeries', {
                  max: MAX_SELECTED_SERIES
                })}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Button size='small' onClick={() => handleSelectAll(true)} disabled={allSelected}>
                {t('analytics.proofOfPlay.charts.filters.selectAll')}
              </Button>
              <Button size='small' onClick={() => handleSelectAll(false)} disabled={noneSelected}>
                {t('analytics.proofOfPlay.charts.filters.deselectAll')}
              </Button>
            </Box>
          </Box>
          <List dense>
            {groupedDataKeys.map(key => (
              <ListItem key={key} button onClick={() => handleToggleSeries(key)} dense>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <Checkbox edge='start' checked={visibleSeries[key]} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={groupedData[key].name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
      <LineChart
        slotProps={{ legend: { hidden: filteredSeries.length > 6 } }}
        xAxis={[
          {
            id: 'dates',
            data: xAxisData.map(date => new Date(date)),
            scaleType: 'time',
            valueFormatter: d => (d == null ? '-' : dateFormat(d, 'dd.mm.yyyy')),
            min: startDate ? new Date(startDate) : undefined,
            max: endDate ? new Date(endDate) : undefined
          }
        ]}
        yAxis={
          isDurationChart(chartType)
            ? [
                {
                  id: 'duration',
                  valueFormatter: formatDurationAxis
                }
              ]
            : undefined
        }
        series={filteredSeries}
        height={380}
      />
    </Fragment>
  )
}

export default ProofOfPlayLineChart
