/* eslint-disable @typescript-eslint/camelcase */
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress, MenuItem } from '@mui/material'
import {
  CountPlaysByAttribute,
  ProofOfPlayReportCountPlaysByAttribute,
  QueryProofOfPlayRequest,
  TimeInterval
} from '@seesignage/seesignage-utils'
import ErrorMessage from '../../../../../components/Errors/ErrorMessage'
import { bindSubmitActionToPromise } from '../../../../../utils/forms'
import { createProofOfPlayReport } from '../../../../../actions/analytics'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import { CreateProofOfPlayReportFormData } from '../../../../../types/analytics'
import { selectProofOfPlayQuery } from '../../../../../selectors/analytics'

export const PROOF_OF_PLAY_REPORT_FORM_NAME = 'ProofOfPlayReportForm'

const countPlaysByAttributeOptions = Object.values(ProofOfPlayReportCountPlaysByAttribute)

const isProofOfPlayQueryValidForReport = (query?: QueryProofOfPlayRequest) => {
  if (query) {
    const { playlistIds, channelIds, countPlaysByAttribute, interval } = query
    const isValid =
      playlistIds?.length === 1 &&
      (channelIds === undefined || channelIds?.length === 0) &&
      countPlaysByAttribute === CountPlaysByAttribute.playlist_id &&
      interval === TimeInterval.day
    return isValid
  }
  return false
}

type ComponentProps = {}

type ProofOfPlayReportFormProps = ComponentProps

const ProofOfPlayReportForm: React.FC<ProofOfPlayReportFormProps &
  InjectedFormProps<CreateProofOfPlayReportFormData, ComponentProps>> = ({
  handleSubmit,
  submitting,
  error
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const query = useSelector(selectProofOfPlayQuery)
  const submitAction = bindSubmitActionToPromise(dispatch, createProofOfPlayReport)
  const isFormValid = isProofOfPlayQueryValidForReport(query)
  const isCreateDisabled = submitting || !isFormValid
  const errorMessage = !isFormValid
    ? t('analytics.proofOfPlay.forms.pdfReport.errors.formInvalid')
    : error
  return (
    <Fragment>
      <form onSubmit={handleSubmit(submitAction)}>
        <Grid container marginTop={1}>
          <Grid item xs={12} paddingX={0.5} paddingY={1}>
            <Field
              label={t('analytics.proofOfPlay.forms.pdfReport.countPlaysByAttribute')}
              name='countPlaysByAttribute'
              component={ReduxTextField}
              fullWidth
              variant='outlined'
              size='small'
              required
              select>
              {countPlaysByAttributeOptions.map(countPlaysByAttribute => (
                <MenuItem key={countPlaysByAttribute} value={countPlaysByAttribute}>
                  {t(
                    `analytics.proofOfPlay.forms.pdfReport.countPlaysByAttributeOptions.${countPlaysByAttribute}`
                  )}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          {errorMessage && (
            <Grid item xs={12} paddingX={0.5} paddingY={1}>
              <ErrorMessage message={errorMessage} />
            </Grid>
          )}
          <Grid item xs={12}>
            <DialogActions>
              <Button disabled={isCreateDisabled} color='primary' type='submit'>
                {t('general.create')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      {submitting && <LinearProgress />}
    </Fragment>
  )
}

export default reduxForm<CreateProofOfPlayReportFormData, ComponentProps>({
  form: PROOF_OF_PLAY_REPORT_FORM_NAME
})(ProofOfPlayReportForm)
