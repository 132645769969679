import React from 'react'
import { AppBar, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import SearchField from '../../../components/SearchField'
import CreateListForm from '../Forms/CreateListForm'
import { OpenDialogButtonType } from '../../../types/dialogs'
import Dialog from '../../Dialog'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import {
  createList as createListAction,
  searchLists as searchListsAction
} from '../../../actions/lists'
import { closeDialog as closeDialogAction } from '../../../actions/dialogs'

const useStyles = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
}))

const ListToolbar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const createList = bindSubmitActionToPromise(dispatch, createListAction)
  const searchLists = (searchTerm: string) => dispatch(searchListsAction(searchTerm))
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))

  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <SearchField
          placeholder={t('general.search')}
          onChange={e => searchLists(e.target.value)}
        />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Dialog
            dialogId='CreateListDialog'
            title={t('lists.create')}
            openDialogButtonType={OpenDialogButtonType.add}
            tooltipTitle={t('lists.create')}
            Content={
              <CreateListForm
                dialogId='CreateListDialog'
                submitAction={createList}
                closeDialog={closeDialog}
                submitButtonLabel={t('general.create')}
              />
            }
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default ListToolbar
