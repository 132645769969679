import { push, LOCATION_CHANGE } from 'connected-react-router'
import { all, takeLatest, put, select, take, call } from 'redux-saga/effects'
import { isMobile } from 'react-device-detect'
import { Action } from 'redux'
import { LunchList, Environment } from '@seesignage/seesignage-utils'
import { logout } from '../actions/users'
import {
  getLists,
  getList,
  deselectListItem,
  clearSearchLists,
  deselectAllListItems,
  getListSuccess,
  resetLists
} from '../actions/lists'
import {
  listPlaylists,
  getPlaylist,
  deselectAllPlaylistItems,
  resetPlaylists,
  getPlaylistSuccess
} from '../actions/playlists'
import {
  listScreens,
  resetScreens,
  selectScreen,
  setScreensInitialLoad,
  deselectAllScreens
} from '../actions/screens'
import {
  selectEnvironmentIdFromPathname,
  selectLocationPathname,
  selectContentIdFromPathname,
  selectScreenCodeFromPathname,
  selectQueryParamFromSearch
} from '../selectors/routing'
import { navigateToEnvironment, navigateToEnvironmentSuccess, navigate } from '../actions/routes'
import { initializeIot } from '../actions/iot'
import { getEnvironment, getEnvironmentFail, getEnvironmentSuccess } from '../actions/environments'
import { listFiles, clearSelection, clearSearchMedia, resetMedia } from '../actions/media'
import {
  selectSelectedEnvironmentId,
  selectEnvironmentById,
  getEnvironmentType
} from '../selectors/environments'
import { selectUserIsAuthenticated } from '../selectors/users'
import {
  listTemplates,
  getTemplate,
  resetTemplates,
  getTemplateSuccess,
  selectCurrentChildIndex
} from '../actions/templates'
import {
  getCustomers,
  getCustomersAndProducts,
  listAllCustomers,
  resetCustomers,
  deselectCustomerRow
} from '../actions/customers'
import { clearSuggestions, resetProducts } from '../actions/products'
import {
  listChannels,
  getChannel,
  closeEditChannelItem,
  resetChannels,
  getChannelSuccess,
  listChannelsSuccess,
  listChannelItems
} from '../actions/channels'
import { closeDialog } from '../actions/dialogs'
import { setGaPageView } from '../config/ga'
import { selectCurrentList } from '../selectors/lists'
import { resetContents } from '../actions/contents'
import { getInfopage, resetInfopages, setInfopagesInitialLoad } from '../actions/infopages'
import ScreensService from '../services/api/screens'
import { runTour } from '../actions/tours'
import { Tour } from '../types/tours'
import { listConfirmations } from '../actions/confirmations'
import { setInitialMockData } from '../actions/mocks'
import { resetCampaigns, listCampaigns, getCampaign } from '../actions/campaigns'
import { EnvironmentType } from '../types/environments'
import { UserSession } from '../types/auth'
import { selectIsScreensInitialLoad } from '../selectors/screens'
import { selectIsInfopagesInitialLoad } from '../selectors/infopages'
import { getSession, getSubFromSession } from '../services/authentication'
import { NavigateToEnvironmentAction } from '../types/actions'
import { resetAnalytics } from '../actions/analytics'
import { hideTawkWidget } from '../config/tawk'

const environmentDependentPaths = [
  '/playlists',
  '/screens',
  '/connections',
  '/media',
  '/lists',
  '/channels',
  '/contents',
  '/templates',
  '/products',
  '/infopages',
  '/edit',
  '/campaigns',
  '/analytics'
]

enum LocationChangeAction {
  /**  "PUSH": A new entry was added to the history stack (e.g., using history.push). */
  PUSH = 'PUSH',
  /** Navigation caused by a browser action like back/forward buttons. */
  POP = 'POP',
  /** "REPLACE": The current entry in the history stack was replaced (e.g., using history.replace). */
  REPLACE = 'REPLACE'
}
interface LocationChangeParams extends Action {
  type: typeof LOCATION_CHANGE
  payload: {
    location: {
      pathname: string
      search: string
      hash: string
      key: string
    }
    action: LocationChangeAction
    isFirstRendering: boolean
  }
}

export function* handleLocationChange({
  payload: {
    location: { pathname },
    action
  }
}: LocationChangeParams) {
  const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
  const contentId: string | undefined = yield select(selectContentIdFromPathname)
  const screenCode = yield select(selectScreenCodeFromPathname)

  switch (pathname) {
    case '/':
      const user = yield select(selectUserIsAuthenticated)
      if (user) {
        yield put(push('/home'))
      } else {
        yield put(push(`/login`))
      }
      break
    case '/home':
      yield put(listScreens())
      if (!isMobile) {
        // run navigation tour only for desktop
        yield put(runTour(Tour.navigation))
      }
      break
    case '/logout':
      yield put(logout())
      break
    case `/environments/${environmentId}/edit`:
      yield all([
        put(getEnvironment({ environmentId })),
        put(listConfirmations(environmentId)),
        put(listScreens())
      ])
      break
    case `/environments/${environmentId}/campaigns`:
      const campaignId: string | null = yield select(selectQueryParamFromSearch('campaignId'))
      yield put(listTemplates())
      yield put(listCampaigns())
      yield put(getCustomers())
      // navigated straight to EditPlaylist
      if (campaignId) {
        yield put(getCampaign(campaignId))
      }
      // Environment might be undefined if first page in application
      let currentEnvironment: Environment | undefined = yield select(
        selectEnvironmentById(environmentId)
      )
      if (currentEnvironment === undefined) {
        // then we need to wait until environment is loaded
        yield take([getEnvironmentSuccess])
        currentEnvironment = yield select(selectEnvironmentById(environmentId))
      }
      if (currentEnvironment) {
        const environmentType: EnvironmentType = getEnvironmentType(currentEnvironment)
        if (campaignId) {
          // drawer open
          yield put(
            runTour(
              environmentType === EnvironmentType.hyper ? Tour.editCampaign : Tour.editSubCampaign
            )
          )
        } else {
          yield put(
            runTour(
              environmentType === EnvironmentType.hyper ? Tour.createCampaign : Tour.subCampaignView
            )
          )
        }
      }
      break
    case `/environments/${environmentId}/playlists`:
      const playlistId: string | null = yield select(selectQueryParamFromSearch('playlistId'))
      // navigated straight to EditPlaylist
      if (playlistId) {
        yield all([
          put(deselectAllPlaylistItems()),
          put(getCustomers()),
          put(getPlaylist(playlistId)),
          put(clearSuggestions()),
          put(listTemplates())
        ])
        // run create playlist item tour after all required actions are ready.
        yield take([getPlaylistSuccess])
        yield put(runTour(Tour.createPlaylistItem))
      }
      break
    case `/environments/${environmentId}/screens`:
      const isInitialLoad: boolean = yield select(selectIsScreensInitialLoad)
      if (isInitialLoad) {
        yield all([
          put(listScreens()),
          put(getLists()),
          put(listFiles()),
          put(setScreensInitialLoad())
        ])
      }

      // Fetch playlists and channels always when navigating to screens view because parent content will be overwritten in playlist and channel view.
      // However, do not fetch those when history.replace (used in ScreensSearchField.tsx)
      if (action !== LocationChangeAction.REPLACE) {
        yield all([
          put(listPlaylists({ includeParentPlaylists: true })),
          put(listChannels({ includeParentChannels: true }))
        ])
      }

      break
    case `/s/${screenCode}`:
      const screen = yield call(ScreensService.getScreenByCode, screenCode)
      if (screen) {
        const { environmentId: screenEnvironmentId, screenId } = screen
        if (screenEnvironmentId !== environmentId) {
          // navigate to environment if different environment and it will select screenId
          yield put(navigateToEnvironment({ environmentId: screenEnvironmentId, screenId }))
        } else {
          yield put(deselectAllScreens()) // screens might be already selected
          yield put(selectScreen(screenId))
          yield put(push(`/environments/${environmentId}/screens`))
        }
      } else {
        yield put(push('/home'))
      }
      break
    case `/environments/${environmentId}/analytics`:
      // hide in analytics view because it is on the way
      hideTawkWidget()
      break
    case `/environments/${environmentId}/connections`:
      yield put(listScreens({ includeAllStatuses: true }))
      break
    case `/environments/${environmentId}/media`:
      yield put(clearSearchMedia())
      yield put(clearSelection())
      break
    case `/environments/${environmentId}/lists`:
      yield put(clearSearchLists())
      yield put(getCustomers())
      yield put(listTemplates())
      yield put(getLists())
      break
    case `/environments/${environmentId}/lists/${contentId}`:
      yield put(getList())
      yield put(clearSuggestions())
      yield put(deselectAllListItems())
      yield put(getCustomers())
      yield put(listTemplates())
      break
    case `/environments/${environmentId}/miscLists/${contentId}`:
      yield put(getList())
      yield put(clearSuggestions())
      yield put(getCustomers())
      break
    case `/environments/${environmentId}/lunchLists/${contentId}`:
      yield put(getList())
      yield put(clearSuggestions())
      yield put(deselectListItem())
      yield put(getCustomers())
      yield take([getListSuccess])
      const { templateId, templateEnvironmentId }: LunchList = yield select(selectCurrentList)
      yield put(getTemplate({ templateId, environmentId: templateEnvironmentId }))
      break
    case `/environments/${environmentId}/templates/${contentId}`:
      yield put(getTemplate({ environmentId, templateId: contentId }))
      yield take([getTemplateSuccess])
      yield put(setInitialMockData())
      break
    case `/environments/${environmentId}/templates`:
      yield put(listTemplates())
      yield put(selectCurrentChildIndex(0))
      break
    case `/environments/${environmentId}/products`:
      yield put(getCustomersAndProducts())
      break
    case `/environments/${environmentId}/channels`:
      yield put(listChannels())

      // run create channel product tour after all required actions are ready.
      yield take([listChannelsSuccess])
      yield put(runTour(Tour.createChannel))
      break
    case `/environments/${environmentId}/channels/${contentId}`:
      yield put(closeEditChannelItem())
      yield put(listPlaylists())
      yield put(listCampaigns())
      yield put(listTemplates())
      yield put(getChannel())
      yield put(listChannelItems())

      // run create channel item product tour after all required actions are ready.
      yield take([getChannelSuccess])
      yield put(runTour(Tour.createChannelItem))

      break
    case `/integrations`:
      yield put(listAllCustomers())
      yield put(deselectCustomerRow())
      break
    case `/environments/${environmentId}/contents/${contentId}`:
      break
    case `/environments/${environmentId}/infopages`:
      const isInitialInfopagesLoad: boolean = yield select(selectIsInfopagesInitialLoad)
      if (isInitialInfopagesLoad) {
        yield all([put(listTemplates()), put(setInfopagesInitialLoad())])
      }
      break
    case `/environments/${environmentId}/infopages/${contentId}`:
      yield put(getInfopage())
      break
    default:
      break
  }

  // Register route changes to Google Analytics
  setGaPageView(pathname)
}

const getEnvironmentDependentPageNameFromPath = (currentPath: string) => {
  if (currentPath.includes('/playlists')) {
    return 'playlists'
  } else if (currentPath.includes('/screens')) {
    return 'screens'
  } else if (currentPath.includes('/media')) {
    return 'media'
  } else if (currentPath.includes('/connections')) {
    return 'connections'
  } else if (currentPath.includes('/lists')) {
    return 'lists'
  } else if (currentPath.includes('/templates')) {
    return 'templates'
  } else if (currentPath.includes('/products')) {
    return 'products'
  } else if (currentPath.includes('/channels')) {
    return 'channels'
  } else if (currentPath.includes('/infopages')) {
    return 'infopages'
  } else if (currentPath.includes('/contents')) {
    return 'contents'
  } else if (currentPath.includes('/edit')) {
    return 'edit'
  } else if (currentPath.includes('/campaigns')) {
    return 'campaigns'
  } else if (currentPath.includes('/analytics')) {
    return 'analytics'
  }
}

export function* handleNavigateToEnvironment({
  payload: { environmentId, screenId }
}: NavigateToEnvironmentAction) {
  const currentPath = yield select(selectLocationPathname)
  const session: UserSession | undefined = yield call(getSession)
  const userId = session ? getSubFromSession(session) : undefined

  yield all([
    put(resetCampaigns()),
    put(resetChannels()),
    put(resetCustomers()),
    put(resetContents()),
    put(resetInfopages()),
    put(resetLists()),
    put(resetMedia()),
    put(resetPlaylists()),
    put(resetProducts()),
    put(resetScreens()),
    put(resetTemplates()),
    put(resetAnalytics())
  ])

  yield put(getEnvironment({ environmentId }))
  // Wait until environment has been loaded
  yield take([getEnvironmentSuccess, getEnvironmentFail])
  yield put(getCustomers(environmentId))
  const environmentPage = getEnvironmentDependentPageNameFromPath(currentPath)
  if (screenId) {
    yield put(selectScreen(screenId))
    yield put(push(`/environments/${environmentId}/screens`))
  } else if (environmentPage) {
    yield put(push(`/environments/${environmentId}/${environmentPage}`))
  } else {
    yield put(push(`/`))
  }
  yield put(initializeIot({ environmentId, userId }))
  yield put(navigateToEnvironmentSuccess())
}

interface HandleNavigateParams {
  /** path */
  payload: string
}

export function* handleNavigate({ payload: path }: HandleNavigateParams) {
  const environmentId: string | undefined = yield select(selectSelectedEnvironmentId)
  if (environmentId && environmentDependentPaths.includes(path)) {
    yield put(push(`/environments/${environmentId}${path}`))
  } else {
    yield put(push(path))
  }
  // close possible open dialog
  yield put(closeDialog())
}

function* watchRoutesActions() {
  yield all([
    takeLatest('@@router/LOCATION_CHANGE', handleLocationChange),
    takeLatest(navigateToEnvironment, handleNavigateToEnvironment),
    takeLatest(navigate, handleNavigate)
  ])
}

export default [watchRoutesActions]
