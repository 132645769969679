import { Box, Button, ButtonProps, Menu, MenuItem } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import {
  CountPlaysByAttribute,
  ProofOfPlayReportCountPlaysByAttribute,
  TimeInterval
} from '@seesignage/seesignage-utils'
import DownloadIcon from 'mdi-react/DownloadIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { generateProofOfPlayCsv } from '../../../../../utils/csv'
import { selectProofOfPlayItems, selectProofOfPlayQuery } from '../../../../../selectors/analytics'
import { selectCurrentEnvironmentFeatures } from '../../../../../selectors/environments'
import Dialog from '../../../../Dialog'
import { openDialog } from '../../../../../actions/dialogs'
import ProofOfPlayReportForm from '../Forms/ProofOfPlayReportForm'

const downloadButtonBaseProps: ButtonProps = {
  color: 'primary',
  size: 'small',
  startIcon: <DownloadIcon />
}

const useStyles = makeStyles(() => ({
  tableToolbar: {
    paddingRight: '7px'
  }
}))

const DownloadByPlaylistIdButton = ({
  interval,
  startDate,
  endDate
}: {
  interval: TimeInterval
  startDate: string
  endDate: string
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const items = useSelector(selectProofOfPlayItems)
  const features = useSelector(selectCurrentEnvironmentFeatures)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      {items && (
        <Fragment>
          <Dialog
            dialogId='createProofOfPlayPdfReportDialog'
            title={t('analytics.proofOfPlay.forms.pdfReport.title')}
            noOpenDialogButton
            Content={
              <ProofOfPlayReportForm
                initialValues={{
                  countPlaysByAttribute: ProofOfPlayReportCountPlaysByAttribute.date
                }}
              />
            }
          />
          <Button
            {...downloadButtonBaseProps}
            id='download-pdf-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            {t('general.download')}
          </Button>
          <Menu
            id='download-pdf-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'download-pdf-button'
            }}>
            <MenuItem
              onClick={() => {
                handleClose()
                generateProofOfPlayCsv(
                  items as any,
                  CountPlaysByAttribute.playlist_id,
                  interval,
                  features?.analytics?.proofOfPlay?.supportCCFeatures ? true : false,
                  startDate,
                  endDate
                )
              }}>
              {t('analytics.proofOfPlay.downloadCsvButton')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                dispatch(openDialog('createProofOfPlayPdfReportDialog'))
              }}>
              {t('analytics.proofOfPlay.downloadPdfButton')}
            </MenuItem>
          </Menu>
        </Fragment>
      )}
    </Fragment>
  )
}

const ProofOfPlayTableToolbar = () => {
  const classes = useStyles()
  const query = useSelector(selectProofOfPlayQuery)
  return (
    <GridToolbarContainer className={classes.tableToolbar}>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton color='info' />
      <GridToolbarFilterButton />
      {query?.countPlaysByAttribute === CountPlaysByAttribute.playlist_id && (
        <DownloadByPlaylistIdButton
          interval={query.interval}
          startDate={query.startDate}
          endDate={query.endDate}
        />
      )}
    </GridToolbarContainer>
  )
}

export default ProofOfPlayTableToolbar
