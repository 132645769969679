/* eslint-disable @typescript-eslint/camelcase */
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { SubmissionError, change, formValueSelector } from 'redux-form'
import {
  AutocompleteOption,
  CreateProofOfPlayReportRequest,
  GetProofOfPlayOptionsResponse,
  ProofOfPlayChannelOption,
  ProofOfPlayPlaylistItemQueryResultRow,
  ProofOfPlayPlaylistOption,
  ProofOfPlayQueryResultRowByPlaylist,
  QueryProofOfPlayRequest,
  QueryProofOfPlayResponse,
  ReportLanguage
} from '@seesignage/seesignage-utils'
import Api from '../services/api/screens'
import {
  createProofOfPlayReport,
  createProofOfPlayReportFail,
  createProofOfPlayReportSuccess,
  getProofOfPlayOptions,
  getProofOfPlayOptionsFail,
  getProofOfPlayOptionsSuccess,
  queryProofOfPlay,
  queryProofOfPlayFail,
  queryProofOfPlaySuccess
} from '../actions/analytics'
import i18n from '../translations/i18n'
import {
  HandleCreateProofOfPlayReportParams,
  HandleQueryProofOfPlayParams
} from '../types/formData'
import { compareStrings } from '../utils/sorting'
import { PROOF_OF_PLAY_FORM_NAME } from '../containers/Analytics/Reports/ProofOfPlay/Forms/ProofOfPlayForm'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'
import {
  selectProofOfPlayItems,
  selectProofOfPlayPlaylistItemResults
} from '../selectors/analytics'
import { closeDialog } from '../actions/dialogs'

export function* handleQueryProofOfPlay({
  payload: { formData, resolve, reject }
}: HandleQueryProofOfPlayParams) {
  try {
    const {
      environmentId,
      startDate,
      endDate,
      playlistIds,
      channelIds,
      interval,
      countPlaysByAttribute
    } = formData
    if (environmentId) {
      const query: QueryProofOfPlayRequest = {
        environmentId,
        startDate,
        endDate,
        interval,
        countPlaysByAttribute,
        playlistIds: playlistIds?.map(({ value }) => value),
        channelIds: channelIds?.map(({ value }) => value)
      }
      const response: QueryProofOfPlayResponse = yield call(
        Api.queryProofOfPlay,
        environmentId,
        query
      )
      yield put(queryProofOfPlaySuccess({ query, response }))
    }
    resolve()
  } catch (error) {
    yield put(queryProofOfPlayFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.analytics.somethingWentWrongQueryProofOfPlay')
      })
    )
  }
}

const convertPlaylistsAsAutocompleOptions = (items: ProofOfPlayPlaylistOption[]) =>
  items
    .map(
      ({ playlist_id, playlist_name }) =>
        ({ value: playlist_id, label: playlist_name } as AutocompleteOption)
    )
    .sort(compareStrings('label'))

const convertChannelsAsAutocompleOptions = (items: ProofOfPlayChannelOption[]) =>
  items
    .map(
      ({ channel_id, channel_name }) =>
        ({ value: channel_id, label: channel_name } as AutocompleteOption)
    )
    .sort(compareStrings('label'))

interface HandleGetProofOfPlayOptionsParams {
  payload: {
    /** ISO date string */
    startDate: string
    /** ISO date string */
    endDate: string
    environmentId: string
    screenEnvironmentId: string
  }
}

export function* handleGetProofOfPlayOptions({
  payload: { startDate, endDate, environmentId, screenEnvironmentId }
}: HandleGetProofOfPlayOptionsParams) {
  try {
    // reset form's selected 'channelIds' and 'playlistIds' because those might not exist
    // in the selected date range.
    yield put(change(PROOF_OF_PLAY_FORM_NAME, 'channelIds', undefined))
    yield put(change(PROOF_OF_PLAY_FORM_NAME, 'playlistIds', undefined))
    const { channels, playlists }: GetProofOfPlayOptionsResponse = yield call(
      Api.getProofOfPlayOptions,
      environmentId, // Get options for environment selected from the form field.
      screenEnvironmentId,
      startDate,
      endDate
    )
    yield put(
      getProofOfPlayOptionsSuccess({
        // Show options (channels/playlists) that are from currently navigated environment
        channels: convertChannelsAsAutocompleOptions(channels),
        playlists: convertPlaylistsAsAutocompleOptions(playlists)
      })
    )
  } catch (error) {
    yield put(getProofOfPlayOptionsFail(error.message))
  }
}

function* handleCreateProofOfPlayReport({
  payload: { formData, resolve, reject }
}: HandleCreateProofOfPlayReportParams) {
  try {
    const { countPlaysByAttribute } = formData
    const currentEnvironmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (currentEnvironmentId) {
      const startDate: string = yield select(
        formValueSelector(PROOF_OF_PLAY_FORM_NAME),
        `startDate`
      )
      const endDate: string = yield select(formValueSelector(PROOF_OF_PLAY_FORM_NAME), `endDate`)
      const reportDataFromEnvironmentId: string = yield select(
        formValueSelector(PROOF_OF_PLAY_FORM_NAME),
        `environmentId`
      )
      const playlistIds: AutocompleteOption[] = yield select(
        formValueSelector(PROOF_OF_PLAY_FORM_NAME),
        `playlistIds`
      )
      const items: ProofOfPlayQueryResultRowByPlaylist[] = yield select(selectProofOfPlayItems)
      const playlistItemResults: ProofOfPlayPlaylistItemQueryResultRow[] = yield select(
        selectProofOfPlayPlaylistItemResults
      )

      const createReportRequest: CreateProofOfPlayReportRequest = {
        playlistId: playlistIds[0].value,
        playlistEnvironmentId: reportDataFromEnvironmentId,
        startDate,
        endDate,
        countPlaysByAttribute,
        items,
        playlistItemResults,
        language: i18n.language as ReportLanguage
      }
      const url: string = yield call(
        Api.createProofOfPlayReport,
        currentEnvironmentId,
        createReportRequest
      )
      yield put(createProofOfPlayReportSuccess({ url }))
      const newWindow = window.open(url, '_blank')
      if (newWindow) {
        newWindow.focus()
      }
    }
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(createProofOfPlayReportFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.analytics.somethingWentWrongCreateProofOfPlayReport')
      })
    )
  }
}

function* watchAnalyticsActions() {
  yield all([
    takeLatest(queryProofOfPlay, handleQueryProofOfPlay),
    takeLatest(getProofOfPlayOptions, handleGetProofOfPlayOptions),
    takeLatest(createProofOfPlayReport, handleCreateProofOfPlayReport)
  ])
}

export default [watchAnalyticsActions]
