import React, { Fragment } from 'react'
import classNames from 'classnames'
import {
  PlaylistItemType,
  isVideoPlaylistItem,
  isImagePlaylistItem,
  isRevolverPlaylistItem,
  isUrlPlaylistItem,
  PlaylistItemPreviewPlayer,
  isListPlaylistItemPreviewPlayer
} from '@seesignage/seesignage-utils'
import { Grid, Typography, Tooltip, Chip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ImageIcon from 'mdi-react/FileImageIcon'
import VideoIcon from 'mdi-react/FileVideoIcon'
import InfopagesIcon from 'mdi-react/BookInformationVariantIcon'
import ListIcon from 'mdi-react/TableColumnIcon'
import RevolverIcon from 'mdi-react/FerrisWheelIcon'
import ProductIcon from 'mdi-react/ShoppingIcon'
import BatchProductIcon from 'mdi-react/LabelMultipleIcon'
import UrlIcon from 'mdi-react/LinkIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import CalendarMultipleIcon from 'mdi-react/CalendarMultipleIcon'
import CalendarClockIcon from 'mdi-react/CalendarClockIcon'
import WeatherLightningRainyIcon from 'mdi-react/WeatherLightningRainyIcon'
import { TFunction } from 'i18next'

import { Carousel } from 'react-responsive-carousel'
import Truncate from 'react-truncate'
import { parseProductData } from '../../utils/products'
import { getPlaylistItemScheduleStage } from '../../utils/shcedule'
import { playPreviewPlayerIndex } from '../../utils/previewPlayer'
import { generatePreviewPlayerListContainsText } from '../../utils/lists'
import HtmlTooltip from '../../components/Tooltip/HtmlTooltip'
import { millisToMinutesAndSeconds } from '../../utils/time'
import { playlistPriorityStyleGetter } from '../../utils/channels'
import ItemTags from '../Playlists/EditPlaylist/Items/Cards/ItemTags'
import ThumbnailWithIcon from './ThumbnailIcon'

interface RenderItemCardProps {
  item: PlaylistItemPreviewPlayer
  classes: Record<
    | 'thumbnailContainer'
    | 'thumbnail'
    | 'notVisibleThumbnail'
    | 'notVisible'
    | 'condition'
    | 'media'
    | 'carouselContainer'
    | 'revolverMedia'
    | 'priorityChip',
    string
  >
}

const getItemThumbnail = ({ item, classes }: RenderItemCardProps) => {
  switch (item.type) {
    case PlaylistItemType.video: {
      const { thumbnailUrl } = item
      return {
        title: item.name,
        Thumbnail: thumbnailUrl ? (
          <img className={classes.media} alt='video' src={thumbnailUrl} />
        ) : (
          //note: some old video files does not have thumbnail
          <ThumbnailWithIcon Icon={VideoIcon} />
        ),
        Icon: VideoIcon
      }
    }
    case PlaylistItemType.image: {
      return {
        title: item.name,
        Thumbnail: <img className={classes.media} alt='' src={item.thumbnailUrl} />,
        Icon: ImageIcon
      }
    }
    case PlaylistItemType.product: {
      const { url, image, name } = parseProductData(item.product)
      const thumbnailUrl: string | undefined = url || image?.Pieni?.Url
      return {
        title: name,
        Thumbnail: thumbnailUrl ? (
          <img className={classes.media} alt='product' src={thumbnailUrl} />
        ) : (
          <ThumbnailWithIcon Icon={ProductIcon} />
        ),
        Icon: ProductIcon
      }
    }
    case PlaylistItemType.batchProducts: {
      return {
        title: item.name,
        Thumbnail: <ThumbnailWithIcon Icon={BatchProductIcon} />,
        Icon: BatchProductIcon
      }
    }
    case PlaylistItemType.content: {
      return {
        title: item.name,
        Thumbnail: <img className={classes.media} alt='content' src={item.thumbnailUrl} />,
        Icon: InfopagesIcon
      }
    }
    case PlaylistItemType.url: {
      return {
        title: item.url,
        Thumbnail: <ThumbnailWithIcon Icon={UrlIcon} />,
        Icon: UrlIcon
      }
    }
    case PlaylistItemType.revolver: {
      return {
        title: item.name,
        Thumbnail: (
          <img
            key={item.itemId}
            className={classes.media}
            src={item.revolverItems[0]?.thumbnailUrl}
            alt='revolver'
          />
        ),
        Icon: RevolverIcon
      }
    }
    case PlaylistItemType.list: {
      return {
        title: item.listName,
        Thumbnail: <ThumbnailWithIcon Icon={ListIcon} />,
        Icon: ListIcon
      }
    }
    case PlaylistItemType.infopage: {
      const thumbnailUrl = item.infopage?.thumbnailUrl
      return {
        title: item.infopage?.name,
        Thumbnail: thumbnailUrl ? (
          <img className={classes.media} alt='infopage' src={thumbnailUrl} />
        ) : (
          <ThumbnailWithIcon Icon={ProductIcon} />
        ),
        Icon: InfopagesIcon
      }
    }
    default:
      return {
        title: '',
        Thumbnail: <span>Missing support</span>
      }
  }
}

const useStyles = makeStyles(() => ({
  thumbnailContainer: {
    height: 100,
    display: 'inline-block',
    marginRight: 8,
    '&:hover': {
      boxShadow: '0px 0px 0px 2px #1e88e5'
    },
    cursor: 'pointer'
  },
  thumbnail: {
    height: '100%'
  },
  notVisibleThumbnail: {
    opacity: 0.7
  },
  notVisible: {
    cursor: 'initial',
    backgroundSize: 'cover',
    background: `repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px
    )`
  },
  condition: {
    display: 'inline-block'
  },
  media: {
    maxHeight: '100%'
  },
  carouselContainer: {
    width: 200,
    height: 150
  },
  revolverMedia: {
    maxWidth: '100%',
    maxHeight: 150
  },
  priorityChip: {
    borderRadius: '5px',
    color: '#fff',
    marginRight: 4
  }
}))

interface PreviewPlayerItemsProps {
  item: PlaylistItemPreviewPlayer
  index: number
  t: TFunction
  isVisible: boolean
}

const ItemThumbnail = ({ item, t, index, isVisible }: PreviewPlayerItemsProps) => {
  const classes = useStyles()
  const { title, Thumbnail, Icon } = getItemThumbnail({ item, classes })
  const scheduleStage = item.schedule?.ranges
    ? getPlaylistItemScheduleStage(item.schedule)
    : undefined
  return (
    <HtmlTooltip
      disableTouchListener // otherwise tooltip closes when clicking carousel buttons
      disableFocusListener
      title={
        <Grid container>
          <Grid item xs={2}>
            {Icon && <Icon />}
          </Grid>
          <Grid item xs={10}>
            <Tooltip disableInteractive title={title || ''}>
              <Typography variant='caption'>
                <Truncate width={160} lines={1}>
                  {title}
                </Truncate>
              </Typography>
            </Tooltip>
          </Grid>
          {item.channelName && (
            <Fragment>
              <Grid item xs={2}>
                {Icon && <CalendarMultipleIcon />}
              </Grid>
              <Grid item xs={10}>
                <Tooltip disableInteractive title={item.channelName}>
                  <Typography variant='caption'>
                    <Truncate width={140} lines={1}>
                      {item.channelName}
                    </Truncate>
                  </Typography>
                </Tooltip>
              </Grid>
            </Fragment>
          )}
          {item.playlistName && typeof item.priority === 'number' && (
            <Fragment>
              <Grid item xs={2}>
                {Icon && <PlaylistPlayIcon />}
              </Grid>
              <Grid item xs={10}>
                <Tooltip disableInteractive title={item.playlistName}>
                  <Typography variant='caption'>
                    <Truncate width={140} lines={1}>
                      {item.playlistName}
                    </Truncate>
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  className={classes.priorityChip}
                  size='small'
                  label={t(`channels.forms.priorities.${item.priority}`)}
                  style={playlistPriorityStyleGetter(item.priority)}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={12}>
            {isVideoPlaylistItem(item) && typeof item.duration === 'number' && (
              <Typography noWrap variant='caption'>
                {t('media.duration')}: {millisToMinutesAndSeconds(item.duration)}
              </Typography>
            )}
            {(isImagePlaylistItem(item) || isUrlPlaylistItem(item)) &&
              typeof item.interval === 'number' && (
                <Typography noWrap variant='caption'>
                  {t('media.duration')}: {millisToMinutesAndSeconds(item.interval * 1000)}
                </Typography>
              )}
            {isListPlaylistItemPreviewPlayer(item) && (
              <Typography noWrap variant='caption'>
                {generatePreviewPlayerListContainsText(item, t)}
              </Typography>
            )}
            {isRevolverPlaylistItem(item) && (
              <div className={classes.carouselContainer}>
                <Carousel
                  statusFormatter={(current, total) => `${current} / ${total}`}
                  showThumbs={false}
                  showIndicators={false}
                  infiniteLoop>
                  {item.revolverItems.map(media => (
                    <img
                      key={item.itemId}
                      className={classes.revolverMedia}
                      src={media.thumbnailUrl}
                      alt='revolver item'
                    />
                  ))}
                </Carousel>
              </div>
            )}
            {item.conditions?.weather && (
              <span className={classes.condition}>
                <Tooltip disableInteractive title={t('playlists.card.itemHasWeather')}>
                  <div>
                    <WeatherLightningRainyIcon color='rgba(0,0,0,0.5)' />
                  </div>
                </Tooltip>
              </span>
            )}
            {scheduleStage && (
              <span className={classes.condition}>
                <Tooltip
                  disableInteractive
                  title={
                    scheduleStage?.visibility === 'ended'
                      ? (t('playlists.card.itemScheduleEnded') as string)
                      : (t('playlists.card.itemIsScheduled') as string)
                  }>
                  <div>
                    <CalendarClockIcon
                      color={scheduleStage.visibility === 'ended' ? 'error' : 'rgba(0,0,0,0.5)'}
                    />
                  </div>
                </Tooltip>
              </span>
            )}
          </Grid>
          {item.conditions?.tags && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <ItemTags tags={item.conditions.tags} t={t} isPreviewItem />
            </Grid>
          )}
          {!isVisible && (
            <Grid item xs={12}>
              <Typography variant='body2' color='secondary'>
                {t('screens.preview.notVisibleByConditions')}
              </Typography>
            </Grid>
          )}
        </Grid>
      }>
      <div
        key={item.itemId}
        className={classNames({
          [classes.thumbnailContainer]: true,
          [classes.notVisible]: !isVisible
        })}
        onClick={() => {
          if (isVisible) {
            playPreviewPlayerIndex(index)
          }
        }}>
        <div
          className={classNames({
            [classes.thumbnail]: true,
            [classes.notVisibleThumbnail]: !isVisible
          })}>
          {Thumbnail}
        </div>
      </div>
    </HtmlTooltip>
  )
}

export default ItemThumbnail
