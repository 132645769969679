import {
  Customer,
  CustomerFormValues,
  Environment,
  EnvironmentUI
} from '@seesignage/seesignage-utils'
import {
  CustomerFormValuesUI,
  UpdateCustomerFormValuesUI,
  UpdateCustomerRequestBody
} from '../types/customers'

const isPrefillSupported = (customer: Customer) => customer.integration?.retail?.prefill

const getCustomerFormInitialValues = (
  { customerId, environmentId, images, name, productForm, language, tables, integration }: Customer,
  { name: environmentName }: Environment | EnvironmentUI
): UpdateCustomerFormValuesUI => ({
  customerId,
  environmentId: {
    value: environmentId,
    label: environmentName
  },

  images,
  name,
  productForm,
  language,
  tables,
  integration
})

const convertCreateCustomerFormValuesForBackend = (
  formValues: CustomerFormValuesUI | UpdateCustomerFormValuesUI
): CustomerFormValues => {
  const { environmentId } = formValues

  return {
    ...formValues,
    environmentId: environmentId.value
  }
}

const convertUpdateCustomerFormValuesForBackend = (formValues: UpdateCustomerFormValuesUI) => {
  const updateCustomerBody: any = { ...formValues }
  delete updateCustomerBody.environmentId
  delete updateCustomerBody.customerId
  // delete whole integration if its contents are not valid
  if (
    updateCustomerBody?.integration?.retail?.dataSource === '' ||
    updateCustomerBody?.integration?.retail?.storeId === ''
  ) {
    updateCustomerBody.integration.retail = null
  } else if (updateCustomerBody?.integration?.emmi?.url === '') {
    updateCustomerBody.integration.emmi = null
  }

  return updateCustomerBody as UpdateCustomerRequestBody
}

export {
  isPrefillSupported,
  getCustomerFormInitialValues,
  convertCreateCustomerFormValuesForBackend,
  convertUpdateCustomerFormValuesForBackend
}
