import {
  ProofOfPlayQueryResultRowByChannel,
  ProofOfPlayQueryResultRowByPlaylist,
  TimeInterval
} from '@seesignage/seesignage-utils'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectProofOfPlayQuery } from '../../../../../selectors/analytics'
import ProofOfPlayLineChart from './ProofOfPlayLineChart'

const ProofOfPlayCharts = ({
  items
}: {
  items: (ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel)[] | undefined
}) => {
  const query = useSelector(selectProofOfPlayQuery)

  return (
    <Fragment>
      {items && query?.interval === TimeInterval.day && query.countPlaysByAttribute && (
        <ProofOfPlayLineChart countPlaysByAttribute={query.countPlaysByAttribute} items={items} />
      )}
    </Fragment>
  )
}

export default ProofOfPlayCharts
