import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import { PlaylistItemTagConditions, LogicalOperator } from '@seesignage/seesignage-utils'
import TagMultipleIcon from 'mdi-react/TagMultipleIcon'
import { Typography, Badge } from '@mui/material'
import colors from '../../../../../styles/common/colors'
import HtmlTooltip from '../../../../../components/Tooltip/HtmlTooltip'
import Tags from '../../../../../components/Chips/Tags'

const parseTagsForRender = (tags: PlaylistItemTagConditions[]) =>
  tags.reduce(
    ({ andPools, orPools }, { operator, not, values }) => {
      if (operator === LogicalOperator.and) {
        andPools.push({ tags: values, not: !!not })
      }
      if (operator === LogicalOperator.or) {
        orPools.push({ tags: values, not: !!not })
      }
      return {
        andPools,
        orPools
      }
    },
    {
      andPools: [] as { tags: string[]; not: boolean }[],
      orPools: [] as { tags: string[]; not: boolean }[]
    }
  )

interface ItemTagsProps {
  tags: PlaylistItemTagConditions[]
  isPreviewItem?: boolean
  dense?: boolean
  t: TFunction
}

const ItemTags: React.FC<ItemTagsProps> = ({ isPreviewItem, t, tags, dense = false }) => {
  const itemTags = tags.map(tag => tag.values).flat()
  const { andPools, orPools } = parseTagsForRender(tags)

  // Define styles based on dense prop
  const badgeStyle = dense
    ? {
        transform: 'scale(0.8)',
        '& .MuiBadge-badge': {
          fontSize: '10px',
          height: '16px',
          minWidth: '16px',
          padding: '0 4px'
        }
      }
    : undefined
  const iconStyle = dense ? { fontSize: '18px' } : undefined

  return (
    <Fragment>
      {itemTags.length > 0 && (
        <div style={isPreviewItem ? undefined : { position: 'absolute', right: 70, bottom: 5 }}>
          <HtmlTooltip
            title={
              <Fragment>
                <Typography variant='body1' color='primary'>
                  {t('playlists.card.tagConditions')}
                </Typography>
                {orPools &&
                  orPools.map(({ tags, not }, index) => (
                    <Fragment key={`or.${index}`}>
                      <Typography variant='caption'>
                        {t(
                          `playlists.editPlaylist.${
                            not ? 'orOperatorNotExplanationTooltip' : 'orOperatorExplanationTooltip'
                          }`
                        )}
                      </Typography>
                      <div>
                        <Tags tags={tags} color='primary' />
                      </div>
                      {index < orPools.length - 1 && <Typography align='center'> + </Typography>}
                    </Fragment>
                  ))}
                {andPools && andPools.length > 0 && orPools.length > 0 && (
                  <Typography align='center'> + </Typography>
                )}
                {andPools &&
                  andPools.map(({ tags, not }, index) => (
                    <Fragment key={`and.${index}`}>
                      <Typography variant='caption'>
                        {t(
                          `playlists.editPlaylist.${
                            not
                              ? 'andOperatorNotExplanationTooltip'
                              : 'andOperatorExplanationTooltip'
                          }`
                        )}
                      </Typography>
                      <div>
                        <Tags tags={tags} color='primary' />
                      </div>
                      {index < andPools.length - 1 && <Typography align='center'> + </Typography>}
                    </Fragment>
                  ))}
              </Fragment>
            }>
            <Badge badgeContent={itemTags.length} color='primary' sx={badgeStyle}>
              <TagMultipleIcon color={colors.seesignageColor} style={iconStyle} />
            </Badge>
          </HtmlTooltip>
        </div>
      )}
    </Fragment>
  )
}

export default ItemTags
