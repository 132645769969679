import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { FieldArray } from 'redux-form'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Dispatch } from 'redux'
import {
  SchemaComponents,
  TemplateType,
  ComponentType,
  isMasterTemplateUI
} from '@seesignage/seesignage-utils'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import { selectTemplateById, selectSelectedTemplateChildIndex } from '../../../selectors/templates'
import { StateInterface } from '../../../types/states'
import FieldsExpansionPanel from '../../../components/ExtensionPanels/FieldsExpansionPanel'
import NestedFieldContainer from '../../../components/NestedFieldContainer'
import {
  AddTemplateChild,
  RemoveTemplateChild,
  ReorderTemplateChild,
  DuplicateTemplateChild,
  EditSchemaComponent,
  AddTemplateChildParams,
  TemplateChildParams,
  DuplicateTemplateChildParams,
  ReorderTemplateChildParams,
  CloseDialog
} from '../../../types/actions'
import {
  addTemplateChild,
  removeTemplateChild,
  duplicateTemplateChild,
  reorderTemplateChild,
  editSchemaComponent
} from '../../../actions/templates'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { closeDialog } from '../../../actions/dialogs'
import { StateTemplate } from '../../../types/templates'
import { isTemplateComponentStylesAllowed } from '../../../utils/templates'
import DivFields from './Fields/DivFields'
import ImageFields from './Fields/ImageFields'
import VideoFields from './Fields/VideoFields'
import DateFields from './Fields/DateFields'
import SocialMediaFields from './Fields/SocialMediaFields'
import TextFields from './Fields/TextFields'
import ExtensionPanelActions from './ExtensionPanelActions'
import { generateFieldArrayPrefix } from './Fields/utils'
import QrCodeFields from './Fields/QrCodeFields'

const formComponents: any = {
  div: DivFields,
  text: TextFields,
  image: ImageFields,
  video: VideoFields,
  date: DateFields,
  qrCode: QrCodeFields,
  listPrice: DivFields,
  lunchGroups: DivFields,
  lunchGroup: DivFields,
  lunchGroupPrice: DivFields,
  lunchItem: DivFields,
  lunchItemsContainer: DivFields,
  lunchItemContainer: DivFields,
  lunchItemSpecialDiet: DivFields,
  priceItems: DivFields,
  products: DivFields,
  productData: DivFields,
  socialMedia: SocialMediaFields,
  // misc items styles can't be modified
  fishItems: Fragment,
  meatItems: Fragment
}

const generateExtensionPanelProps = ({
  level,
  currentComponentSchema,
  parentComponentType,
  closeDialog,
  addTemplateChild,
  removeTemplateChild,
  duplicateTemplateChild,
  editSchemaComponent,
  childIndexes,
  currentIndex,
  fields,
  templateType
}: RecursiveFormSchemaProps) => {
  const fieldArrayName = fields ? fields.name : undefined
  return {
    schemaComponents: currentComponentSchema,
    Actions: ExtensionPanelActions,
    actionsProps: {
      currentComponentSchema,
      level,
      closeDialog,
      addTemplateChild,
      removeTemplateChild,
      duplicateTemplateChild,
      parentComponentType,
      editSchemaComponent,
      childIndexes,
      fieldArrayName,
      currentIndex,
      templateType
    },
    level,
    fieldsName: fieldArrayName
  }
}

// react-beautiful-dnd does not support nested droppables
const isDragDisabled = (level: number, parentComponentType: ComponentType) =>
  level === 0 ||
  level > 2 ||
  parentComponentType === ComponentType.meatItems ||
  parentComponentType === ComponentType.fishItems ||
  parentComponentType === ComponentType.lunchGroups ||
  parentComponentType === ComponentType.lunchGroupPrice ||
  parentComponentType === ComponentType.listPrice ||
  parentComponentType === ComponentType.lunchItemsContainer

const isDropDisabled = (droppableId: string, level: number, currentSource?: CurrentSourceProps) => {
  if (currentSource) {
    const { currentDraggableLevel, currentDroppableId, currentSourceIndex } = currentSource
    const droppableIdTemp = `${
      currentDroppableId === 'none' ? currentSourceIndex : currentDroppableId
    }`
    return (
      currentDraggableLevel !== undefined &&
      level > currentDraggableLevel &&
      droppableId.startsWith(droppableIdTemp)
    )
  }
  return false
}

const boxShadow =
  'rgba(0, 0, 0, 0.2) 0px 4px 4px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px'

const getDroppableStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'rgb(145, 225, 255)' : '#fafafa',
  padding: '8px 4px 8px 4px',
  boxShadow
})

interface RecursiveFormSchemaProps {
  closeDialog: CloseDialog
  addTemplateChild: AddTemplateChild
  removeTemplateChild: RemoveTemplateChild
  duplicateTemplateChild: DuplicateTemplateChild
  editSchemaComponent: EditSchemaComponent
  /** parent component type required in extension panel actions conditions */
  parentComponentType?: any
  currentComponentSchema: SchemaComponents
  level: number
  childIndexes: number[]
  currentIndex: number
  currentSource?: CurrentSourceProps
  /** props from Redux Form field array component */
  fields?: any
  templateType: TemplateType
  formName: string
}

const RecursiveFormSchema: React.FC<RecursiveFormSchemaProps> = props => {
  const {
    closeDialog,
    addTemplateChild,
    removeTemplateChild,
    duplicateTemplateChild,
    editSchemaComponent,
    currentComponentSchema,
    parentComponentType,
    level,
    childIndexes,
    currentIndex,
    fields,
    currentSource,
    templateType,
    formName
  } = props
  const FormComponent = formComponents[currentComponentSchema.type]
  const componentChildren = currentComponentSchema?.children
  const schemaId = currentComponentSchema.id
  const currentFormProps = {
    id: schemaId,
    level,
    childIndexes,
    type: currentComponentSchema.type,
    formName
  }
  const draggableId = fields ? `${fields.name}-${currentIndex}` : 'root'
  const droppableId = childIndexes.length > 0 ? childIndexes.join(',') : 'none'
  const droppableProps = {
    droppableId,
    type: 'droppableItem',
    isDropDisabled:
      isDropDisabled(droppableId, level, currentSource) ||
      currentComponentSchema.type === ComponentType.lunchGroupPrice ||
      currentComponentSchema.type === ComponentType.lunchGroups
  }
  return (
    <NestedFieldContainer id={schemaId}>
      <Draggable
        key={draggableId}
        draggableId={draggableId}
        index={currentIndex}
        isDragDisabled={isDragDisabled(level, parentComponentType)}>
        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
          <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
            <FieldsExpansionPanel {...generateExtensionPanelProps(props)} isDragging={isDragging}>
              {isTemplateComponentStylesAllowed(currentComponentSchema.type) ? (
                <FormComponent {...currentFormProps} />
              ) : (
                <Fragment />
              )}
            </FieldsExpansionPanel>
            {/* continue recusrion if children */}
            {componentChildren && (
              <Droppable key={droppableId} {...droppableProps}>
                {({ innerRef, placeholder }, { isDraggingOver }) => (
                  <div ref={innerRef} style={getDroppableStyle(isDraggingOver)}>
                    {Object.values(componentChildren).map((schema, childrenIndex) => {
                      const fieldArrayName = generateFieldArrayPrefix(childIndexes)
                      const formProps = {
                        component: RecursiveFormSchema,
                        name: fieldArrayName,
                        props: {
                          closeDialog,
                          addTemplateChild,
                          removeTemplateChild,
                          duplicateTemplateChild,
                          editSchemaComponent,
                          currentComponentSchema: schema,
                          parentComponentType: currentComponentSchema.type,
                          level: level + 1,
                          currentIndex: childrenIndex,
                          childIndexes: [...childIndexes, childrenIndex],
                          rerenderOnEveryChange: true,
                          currentSource,
                          templateType,
                          formName
                        }
                      }
                      return <FieldArray key={`${schema.id}-${childrenIndex}`} {...formProps} />
                    })}
                    {placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </div>
        )}
      </Draggable>
    </NestedFieldContainer>
  )
}

interface CurrentSourceProps {
  currentDraggableLevel?: number
  currentSourceIndex?: number
  currentDroppableId?: string
}

interface OwnProps {
  formName: string
}
interface StateProps {
  template?: StateTemplate
  selectedChildIndex: number
}

interface DispatchProps {
  closeDialog: CloseDialog
  addTemplateChild: AddTemplateChild
  removeTemplateChild: RemoveTemplateChild
  duplicateTemplateChild: DuplicateTemplateChild
  reorderTemplateChild: ReorderTemplateChild
  editSchemaComponent: EditSchemaComponent
}

type TemplateFormFieldsContainerProps = StateProps & DispatchProps & OwnProps

const TemplateFormFieldsContainer: React.FC<TemplateFormFieldsContainerProps> = ({
  closeDialog,
  addTemplateChild,
  removeTemplateChild,
  duplicateTemplateChild,
  reorderTemplateChild,
  editSchemaComponent,
  selectedChildIndex,
  template,
  formName
}) => {
  const [currentSource, setCurrentSource] = useState<undefined | CurrentSourceProps>(undefined)
  if (!template) {
    return <Fragment />
  }
  const currentTemplate = isMasterTemplateUI(template)
    ? template.childTemplates[selectedChildIndex]
    : template

  const formProps = {
    closeDialog,
    addTemplateChild,
    removeTemplateChild,
    duplicateTemplateChild,
    editSchemaComponent,
    currentComponentSchema: currentTemplate.schema.components,
    templateType: currentTemplate.type,
    childIndexes: [],
    currentIndex: 0,
    level: 0,
    currentSource,
    formName
  }
  const droppableId = 'droppable-list-root'
  const droppableProps = {
    droppableId,
    type: 'droppableItem',
    isDropDisabled: true
  }
  return (
    <div style={{ padding: '8px' }}>
      <DragDropContext
        onDragStart={({ source: { droppableId, index } }) => {
          const level = droppableId === 'none' ? 0 : droppableId.split('.').length
          setCurrentSource({
            currentDraggableLevel: level,
            currentSourceIndex: index,
            currentDroppableId: droppableId
          })
        }}
        onDragEnd={({ source, destination }) => {
          if (destination) {
            const sourceId = source.droppableId
            const destinationId = destination.droppableId
            const sourceIndex = source.index
            const destinationIndex = destination.index
            if (sourceId !== destinationId || sourceIndex !== destinationIndex) {
              reorderTemplateChild({
                sourceId,
                destinationId,
                sourceIndex,
                destinationIndex
              })
            }
          }
          setCurrentSource(undefined)
        }}>
        <Droppable key={droppableId} {...droppableProps}>
          {({ innerRef, placeholder }, { isDraggingOver }) => (
            <div ref={innerRef} style={getDroppableStyle(isDraggingOver)}>
              <RecursiveFormSchema {...formProps} />
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  template: selectTemplateById(selectContentIdFromPathname(state))(state),
  selectedChildIndex: selectSelectedTemplateChildIndex(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  addTemplateChild: (params: AddTemplateChildParams) => dispatch(addTemplateChild(params)),
  removeTemplateChild: (params: TemplateChildParams) => dispatch(removeTemplateChild(params)),
  duplicateTemplateChild: (params: DuplicateTemplateChildParams) =>
    dispatch(duplicateTemplateChild(params)),
  reorderTemplateChild: (params: ReorderTemplateChildParams) =>
    dispatch(reorderTemplateChild(params)),
  editSchemaComponent: bindSubmitActionToPromise(dispatch, editSchemaComponent)
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(TemplateFormFieldsContainer)
