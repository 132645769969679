import {
  AutocompleteOption,
  CountPlaysByAttribute,
  ProofOfPlayReportCountPlaysByAttribute,
  TimeInterval
} from '@seesignage/seesignage-utils'

export interface QueryProofOfPlayFormData {
  environmentId: string
  startDate: string
  endDate: string
  // screenIds?: AutocompleteOption[]
  playlistIds?: AutocompleteOption[]
  channelIds?: AutocompleteOption[]
  // keys?: string[]
  interval: TimeInterval
  countPlaysByAttribute?: CountPlaysByAttribute
}

export interface CreateProofOfPlayReportFormData {
  countPlaysByAttribute: ProofOfPlayReportCountPlaysByAttribute
}

export enum ProofOfPlayChartType {
  playsPerPlaylist = 'playsPerPlaylist',
  durationPerPlaylist = 'durationPerPlaylist',
  playsPerChannel = 'playsPerChannel',
  durationPerChannel = 'durationPerChannel',
  playsPerScreen = 'playsPerScreen',
  durationPerScreen = 'durationPerScreen'
}
